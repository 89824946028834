import React, {Fragment, useEffect, useState } from 'react'
import { EditForm } from './../../../Components/Geofence/editForm'
import Button from './../../../Components/common/Button'
import SearchField from './../../../Components/common/SearchField'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { toggleAllGeofences, geoFenceVisible, deleteGeofence } from './../../../Actions/Devices'
// import isEqual from 'react-fast-compare'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { checkPrivileges, errorHandler } from '../../../Helpers'
import ConfirmDialoag from '../../../Components/common/ConfirmDialoag'
import Style from 'style-it'
import Scrollbar from 'react-scrollbars-custom'
import { CheckIcon } from '../../../Components/common/CheckIcon'
import Checkbox from '../../../Components/common/Checkbox'
import Notifications from 'react-notification-system-redux'
import ExportImportMenu from '../../../Components/Maps/ExportImportMenu'
import Loader from '../../Loader'
import RecourcesList from './RecourcesList'
import instance from '../../../axios'
// import PublishIcon from '@mui/icons-material/Publish';
// import { Tooltip } from '@material-ui/core'
const Geofences = (props) => {
  const [state, setState] = useState({
    selectAll: false,
  })
  const [searchValue, setSearchValue] = useState('');
  const search = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (props.resourceList === 'geofences') {
      props.searchItems(value)
    } else if (props.resourceList === 'tracks') {
      props.searchItems(value)
    } else if (props.resourceList === 'groups') {
      props.searchResources(value)
    }
  }
  useEffect(() => {
    setSearchValue('');
    if(props.resourceList==="groups"){
      props.searchResources("")
    }
    // else{

    //   props.getAllGeofences()
    // }
    setState((prevState)=>({
      ...prevState,
      selectAll:false
    }))
  }, [props.resourceList]);
  // const addShape = (type) => {
  //   let element
  //   if (type === 'circle') {
  //     element = document.getElementById('addCircle')
  //   } else if (type === 'polygon') {
  //     element = document.getElementById('addPolygon')
  //   } else if (type === 'polyline') {
  //     element = document.getElementById('addPolyline')
  //   }

  //   if (element) {
  //     element.click()
  //   }
  //   props.collapseSidebar()
  // }

  const toggleGeofences = (e,arr) => {
    setState((prevState)=>({
      ...prevState,
      selectAll:e.target.checked?true:false
    }))
    // let payload = props.resourceList === 'geofences'
    const { resourceList } = props;
    props.toggleGeofences(e.target.checked,arr);
    props.dispatch(toggleAllGeofences(e.target.checked, resourceList,arr));
  }

  // const uploadTrigger = () => {
  //   const el = document.getElementById('uploadTrigger')
  //   el.click()
  // }

  // const kmlFileUpload = (event) => {
  //   if (event && event.target && event.target.files && event.target.files.length) {
  //     const kmlFile = props.kmlFileUpload(event)
  //     if (kmlFile) {
  //       setState((prevState) => ({
  //         ...prevState,
  //         kmlFile: kmlFile
  //       }))
  //     }
  //   }
  // }
if(props){


  const { geofenceData, geoFence, openGeofenceModal, resourceList } = props;
  const isKmlTab=resourceList==="tracks"
  const getUniqueGeofences = geoFence?.filter(item => 
    isKmlTab ? item?.type === "kmlFile" : item?.type !== "kmlFile"
  );
  const geo = props.itemPagination.items.map(g => {
    // Check if resourceList is 'tracks' and attributes.type is 'kmlFile'
    if (resourceList === 'tracks' && g.attributes.type === 'kmlFile') {
      return (
        <ListRow
          key={g.id}
          item={g}
          onDelete={props.onDelete}
          {...geoFence}
          {...geofenceData}
          openGeofenceModal={openGeofenceModal}
        />
      );
    }
    // Check if resourceList is 'geofences' and attributes.type is not 'kmlFile'
    else if (resourceList === 'geofences' && g.attributes.type !== 'kmlFile') {
      return (
        <ListRow
          key={g.id}
          item={g}
          onDelete={props.onDelete}
          {...geoFence}
          {...geofenceData}
          openGeofenceModal={openGeofenceModal}
        />
      );
    }
    // Return null for items that don't meet the conditions
    else {
      return null;
    }
  });


  return (
    <Style>
      {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
      <aside className='fms-sidebar'>
        <div className='fms-sidebar-monitoring'>
          {checkPrivileges('itemgroup') ? <ul className='filter-row clearfix'>
            <li className='filter-nav'>
              <a
                className={
                  'filter-nav-link ' +
                  (props.resourceList === 'geofences' ? 'active' : '')
                }
                href={null}
                onClick={() => props.showResources('geofences')}
              >
                {props.translate('sharedGeofences')}
              </a>
              {checkPrivileges('track') && (
                <a
                  className={
                    'filter-nav-link ' +
                    (props.resourceList === 'tracks' ? 'active' : '')
                  }
                  href={null}
                  onClick={() => props.showResources('tracks')}
                >
                  {props.translate('Tracks')}
                </a>
              )}
              <a
                className={
                  'filter-nav-link ' +
                  (props.resourceList === 'groups' ? 'active' : '')
                }
                href={null}
                onClick={() => props.showResources('groups')}
              >
                {props.translate('groups')}
              </a>
            </li>
          </ul> : null}
          <div className='sidebar-bg-layer'>
            {checkPrivileges('geofence') && (
              <div>
                <div className='section-head clearfix section-head-filter'>
                  <ul className='filter-row clearfix pull-right'>
                    <li>
                   
                      <Checkbox 
                     checked={state.selectAll}
                     onChange={(e)=>toggleGeofences(e,getUniqueGeofences)} 
                       />
                    </li>
                    <li>
                      <SearchField
                        label={props.resourceList === 'geofences' ?
                          props.translate('searchGeofence') :
                          props.resourceList === 'tracks' ?
                            props.translate('searchKMLTracks') :
                            props.resourceList === 'groups' ?
                              props.translate('searchGroups') :
                              null}
                              value={searchValue}
                        fullWidth
                        onChange={e => search(e)}
                      />
                    </li>
                    {props.resourceList === 'groups' ?
                      <li style={{ paddingTop: 8, paddingBottom: 4, paddingRight: 0 }}>
                        {checkPrivileges('itemgroupCreate') && (
                          <Button
                            size='small'
                            fullWidth
                            onClick={() => props.addResource()}
                          >
                            {' '}
                            {props.translate('sharedCreate')}
                          </Button>
                        )}
                      </li> : props.resourceList === 'geofences' || props.resourceList === 'tracks' ?
                        <li style={{ paddingTop: 8, paddingBottom: 4, paddingRight: 0 }}>
                          {checkPrivileges('geofenceCreate') && (
                            <Button
                              size='small'
                              fullWidth
                              onClick={() => props.openGeofenceModal()}
                            >
                              {' '}
                              {props.translate('sharedCreate')}
                            </Button>
                          )}
                        </li>
                        : null}
                    {props.resourceList !== 'tracks' && (
                      <li style={{ paddingTop: 8, paddingBottom: 4, paddingLeft: 0 }}>
                        <ExportImportMenu
                          fileName='geofences'
                          checkPrivilege='geofenceCreate'
                          data={geoFence}
                          themecolors={props.themecolors}
                        />
                      </li>)}
                  </ul>
                </div>
                {props.resourceList === 'groups' ?
                  <RecourcesList
                    ItemChild={ListRow}
                    ItemChildProps={{
                      onDelete: props.onDelete,
                      ...geoFence,
                      ...geofenceData,
                      openGeofenceModal
                    }}
                    fromArray={props.itemPagination.items}
                    {...props} />

                  : props.resourceList === 'geofences' || props.resourceList === 'tracks' ?

                    <ul className='list-view with-padding-right geofence-sidebar-list'>
                      {!props.isGeofenceLoad ? <Scrollbar
                        disableTracksWidthCompensation={true}
                        onScroll={props.resourceList==="geofences"?props.handleScrollGeofencesIndex:null}
                        scrollerProps={{
                          renderer: props => {
                            const { elementRef, ...restProps } = props
                            return (
                              <div
                                {...restProps}
                                ref={elementRef}
                                id='scrollableDiv'
                              />
                            )
                          }
                        }}
                      >
                        {props &&
                          props.geofenceData &&
                          props.geofenceData.newVector &&
                          props.geofenceData.newVector.id && (
                            <ListRow
                              item={props.geofenceData.newVector}
                              {...geofenceData}
                            />
                          )}
                        {geo.length ?
                          geo :
                          <p style={{ textAlign: 'center', color: '#ccc', fontWeight: 'bold', fontSize: 14 }}>
                            {props.translate('notFound')}
                          </p>
                        }
                      </Scrollbar> :
                        <Loader />}
                    </ul>
                    : null
                }
              </div>
            )}
          </div>
        </div>
      </aside>
    </Style>
  )
}
}

const ListRow1 = (props) => {
  const [state, setState] =useState({
    edit: false,
    item: {},
    colorPicker: false
  })

  const onUpdate = (event) => {
    props.dispatch(
      geoFenceVisible({ visible: event.target.checked, id: props.item.id })
    )
    props.updateVisible()
  }
  const onDelete = () => {
    if (props.item.id) {
      setState((prevState) => ({
        ...prevState,
        onDeleteConfirmation: false
      }))
      instance({
        url: `api/geofences/${props.item.id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {// eslint-disable-line no-unused-vars
          props.dispatch(deleteGeofence({ id: props.item.id }))
          setState((prevState) => ({ ...prevState, item: {} }))
       
          props.onDelete(props.collapseIds)
          props.dispatch(
            Notifications.success({
              message: props.translate('geofenceDeleted'),
              autoDismiss: 10
            })
          )
        }).catch(error => { errorHandler(error, props.dispatch) })
      // .catch(e => {
      //   props.dispatch(
      //     Notifications.error({
      //       message: props.translate('somthingWentWrongMessage'),
      //       autoDismiss: 10
      //     })
      //   )
      // })
    }
  }
  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirmation: false
    }))

  }

  const onRemovedItem = () => {
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirmation: true
    }))
  }

  const saveMapChanges = () => {
    setState((prevState) => ({
      ...prevState,
      edit: false
    }))
    props.save({ ...state.item })
  }

  const cancelMapChanges = () => {
    props.cancelMapChanges(props.item.id)
    setState((prevState) => ({
      ...prevState,
      edit: false
    }))
  }

  // const runAction = (id) => {
  //   if (props.EditControlObject && props.EditControlObject[id]) {
  //     props.EditControlObject[
  //       id
  //     ]._toolbars.edit._toolbarContainer.children[0].click()
  //   }
  // }

  const enableEditMap = (event, id) => {
    props.dispatch(geoFenceVisible({ visible: true, id: id }))
    props.openGeofenceModal(props.item)
    /* if (this.props.Drawing === '') {
      this.props.dispatch(geoFenceVisible({ visible: true, id: id }))
      this.setState(
        {
          edit: true,
          item: { ...this.props.item, attributes: {...this.props.item.attributes, speedLimit: convertSpeedUnits((this.props.item.attributes.speedLimit || 0), 'kn', 'kmh')}, visible: true }
        },
        () => {
          props.enableEditMap(id)
        }
      )
    } else {
      alert('Please complete or cancel your new geofence vector first.')
    } */
  }

  const onChangeColor = (color) => {
    setState((prevState) => ({
      ...prevState,
      item: { ...prevState.item, attributes: { ...prevState.item.attributes, color } }
    }))
  }

  const updateGeoFanceSpeedLimit = (speedLimit) => {
    setState((prevState) => ({
      ...prevState,
      item: { ...prevState.item, attributes: { ...prevState.item.attributes, speedLimit } }
    }))
  }

  const updateGeoFancePolylineDistance = (polylineDistance) => {
    setState((prevState) => ({
      ...prevState,
      item: { ...prevState.item, attributes: { ...prevState.item.attributes, polylineDistance: parseFloat(polylineDistance) } }
    }))
  }

  // const showColorPicker = () => {
  //   setState((prevState) => ({ ...prevState, colorPicker: true }))
  // }

  // const hideColorPicker = () => {
  //   setState((prevState) => ({ ...prevState, colorPicker: false }))

  // }

  const updateGeoFanceName = (value) => {
    setState((prevState) => ({ ...prevState, item: { ...prevState.item, name: value } }))
  }

 const updateGeoFanceDescription=(description)=> {
    setState((prevState) => ({ ...prevState, item: { ...prevState.item,description } }))
  }

  const updateGeoFanceCalendar=(calendarId)=> {
    setState((prevState) => ({ ...prevState, item: { ...prevState.item,calendarId } }))
  }



    const EditView = (
      <EditForm
        vector={state.item}
        updateName={updateGeoFanceName}
        updateColor={onChangeColor}
        updateDescription={updateGeoFanceDescription}
        updateCalendar={updateGeoFanceCalendar}
        updateSpeedLimit={updateGeoFanceSpeedLimit}
        updatePolylineDistance={updateGeoFancePolylineDistance}
        save={saveMapChanges}
        cancel={cancelMapChanges}
        translate={props.translate}
      />
    )
    return (
      <li
        className={!state.edit ? 'list-row' : null}
        key={props.item.id}
      >
        {state.edit && EditView}
        {!state.edit && (
          <Fragment>
            <label className='checkbox'>
              <input
                id={props.item.id}
                disabled={state.edit}
                type='checkbox'
                checked={props.item.visible || false}
                onChange={onUpdate}
              />
              <CheckIcon className='theme-input-checkbox' />
              <span className='unit-name-geofence' style={{ fontSize: 12 }}>
                {props.item.name}
              </span>
            </label>
            <div className='callout-actions'>
              {checkPrivileges('geofenceDelete') && (
                <span className='action' onClick={onRemovedItem}>
                  <DeleteIcon className='material-icons' />
                </span>
              )}
              {checkPrivileges('geofenceUpdate') && (
                <span
                  className='action'
                  onClick={(e) => enableEditMap(e, props.item.id)}
                >
                  <EditIcon className='material-icons' />
                </span>
              )}
            </div>

            {state.onDeleteConfirmation && (
              <ConfirmDialoag
                onCancel={onCancel}
                onOk={onDelete}
                title={props.translate('areYouWantToDelete')}
                children={props.item.name}
              />
            )}
          </Fragment>
        )}
      </li>
    )
  }

const mapState = state => ({
  isGeofenceLoad: state.isGeofenceLoad,
  themecolors: state.themeColors
})

const mapStateToProps = connect(mapState)
export const ListRow = mapStateToProps(withLocalize(ListRow1))
export default mapStateToProps(Geofences)
