import React, { Component } from 'react'
import { connect } from 'react-redux'
import { EditItem } from './EditItem'
import CustomDialog from './../common/Dialog'
import {  checkPrivileges, errorHandler, selectAll } from './../../Helpers'
import { withLocalize } from 'react-localize-redux'
// import { withSnackbar } from 'notistack'
import Loader from '../../Layout/Loader'
import isEqual from 'react-fast-compare'
import { addDriver, updateDriver } from './../../Actions/Drivers'
import {
  updateDeviceUserWiseSelectedList,
  RemoveDeviceUserWise,
  addDeviceUserWise
} from './../../Actions/Notifications'
import AddDrivers from './addDrivers'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Notifications from 'react-notification-system-redux'
import CommonTableSection from './../../Pages/Units/Components/commonTableSection'
import axios from 'axios'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import instance from '../../axios'
import Axios from 'axios'


const driverDataFormat = (data, extAttriubtes, itemId) => ({
  id: itemId || 0,
  attributes: { ...extAttriubtes },
  name: data.name && data.name.trim(),
  uniqueId: data.uniqueId && data.uniqueId.trim(),
  expirationTime: data.expirationTime || '',
})
const formDefault = {
  name: '',
  uniqueId: '',
  attributes: {},
  driverAddress: ''
}

class driversModal extends Component {
  constructor () {
    super()
    this.state = {
      dm_isVisable: false,
      selectedDriver: '',
      addOption: false,
      editOption: false,
      dr_attriubtes: '',
      isVisableUserBtn: false,
      isAttChanged: false,
      isVisableTrackerModal: false,
      driverId: '',
      form: formDefault,
      itemAttributes: {},
      attributeChangesMessage: '',
      removedIdPropcess: '',
      imagesData: '',
      waslStatus: '',
      driverStatus: '',
      uploadingImage:false,
      isVisibleBtn:false,
      isLoading:false
    }
    this.onCloseSubModal = this.onCloseSubModal.bind(this)
    this.imageDataPrepare = this.imageDataPrepare.bind(this)
    this.changeAttribute = this.changeAttribute.bind(this)
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
    this.uploadImage = this.uploadImage.bind(this)
  }
  async componentWillMount () {
    if(this.props.activeOperation === ''){
      this.setState({
        editOption: false,
        addOption: false,
      })
    }
    if (this.props.showItemDetail && this.props.trackersApiResponce) {
      let formData = this.setFormData(this.props.selecteditem)
      let attributes = this.props.selecteditem.attributes
      let trackers = this.props.multiTrackers
      formData = { ...formData, attributes, trackers }
      this.setState({
        selectedDriver: formData,
        driverId: this.props.selecteditem.id
      })
    } else {
      if (this.props.activeOperation === 'edit') {
        let formData = await this.setFormData(this.props.selecteditem)
        let attributes = this.props.selecteditem.attributes
        formData = { ...formData, attributes }
        if (
          !this.state.editOption ||
          !isEqual(this.state.selectedDriver, formData)
        ) {
          this.setState(
            {
              driverId: this.props.selecteditem.id,
              selectedDriver: formData,
              itemAttributes: this.props.selecteditem.attributes,
              editOption: true,
              addOption: false,
              dm_isVisable: true,
              attributeChangesMessage: '',
              isVisableUserBtn: false,
              isAttChanged: false,
              dr_attriubtes: this.props.selecteditem.attributes,
              form: { ...formData },
            },
            () => this.checkRequiredFields()
          )
        }
      }
      if (this.props.activeOperation === 'add' && !this.state.addOption) {
        this.setState({
          driverId: this.props.selecteditem.id,
          selectedDriver: '',
          addOption: true,
          editOption: false,
          dm_isVisable: true,
          form: formDefault,
          isVisableUserBtn: false,
          isAttChanged: false,
          attributeChangesMessage: ''
        })
      }
    }
    if (
      this.props.selectedAddress !== '' &&
      this.props.selectedAddress !== undefined
    ) {
      this.setState({
        form: {
          ...this.state.form,
          driverAddress: this.props.selectedAddress
        }
      })
    }
  }
  componentWillReceiveProps (nextprops) {
    if (nextprops.selecteditem.id !== this.state.driverId) {
      if (nextprops.showItemDetail && nextprops.trackersApiResponce) {
        let formData = this.setFormData(nextprops.selecteditem)
        let attributes = nextprops.selecteditem.attributes
        let trackers = nextprops.multiTrackers
        formData = { ...formData, attributes, trackers }
        this.setState({
          selectedDriver: formData,
          driverId: nextprops.selecteditem.id
        })
      } else {
        if (nextprops.activeOperation === 'edit') {
          let formData = this.setFormData(nextprops.selecteditem)
          let attributes = nextprops.selecteditem.attributes
          formData = { ...formData, attributes }
          if (
            !this.state.editOption ||
            !isEqual(this.state.selectedDriver, formData)
          ) {
            this.setState(
              {
                driverId: nextprops.selecteditem.id,
                selectedDriver: formData,
                editOption: true,
                addOption: false,
                dm_isVisable: true,
                attributeChangesMessage: '',
                isVisableUserBtn: false,
                isAttChanged: false,
                itemAttributes: nextprops.selecteditem.attributes,
                dr_attriubtes: nextprops.selecteditem.attributes,
                form: { ...formData }
              },
              () => this.checkRequiredFields()
            )
          }
        }
        if (nextprops.activeOperation === 'add' && !this.state.addOption) {
          this.setState(
            {
              driverId: nextprops.selecteditem.id,
              selectedDriver: '',
              addOption: true,
              editOption: false,
              dm_isVisable: true,
              form: formDefault,
              isVisableUserBtn: false,
              isAttChanged: false,
              attributeChangesMessage: ''
            },
            () => this.checkRequiredFields()
          )
          if (
            nextprops.selectedAddress !== '' &&
            nextprops.selectedAddress !== undefined
          ) {
            this.setState(
              {
                form: {
                  ...this.state.form,
                  driverAddress: nextprops.selectedAddress
                }
              },
              () => this.checkRequiredFields()
            )
          }
        }
      }
    }
    if (
      nextprops.selectedAddress !== '' &&
      nextprops.selectedAddress !== undefined
    ) {
      this.setState(
        {
          form: {
            ...this.state.form,
            driverAddress: nextprops.selectedAddress
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }

  getImage = async (id) => {
    try {
      const response = await Axios.get(`/api/media/driver/${id}`, {
        responseType: 'arraybuffer', // Ensure the response is treated as an array buffer
      });
      const filename = id;
      const parts = filename.split('.');
      const extension = parts[parts.length - 1];
      
      if (response.status === 200) {
        // Convert the array buffer to a Blob object
        const blob = new Blob([response.data], { type: `image/${extension}` });
  
        // Create a FileReader to read the Blob as a data URL
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            // Resolve with the data URL containing the base64-encoded image
            resolve(reader.result);
          };
          reader.onerror = (error) => {
            // Reject with the error if FileReader encounters an error
            reject(error);
          };
          reader.readAsDataURL(blob); // Read the Blob as a data URL
        });
      } else {
        console.error('Failed to fetch image:', response.status, response.statusText);
        return '';
      }
    } catch (error) {
      console.error('Error in getImage:', error);
      return '';
    }
  };
  

  setFormData = async (data) => {
 
    let driverImg;

      if ( process.env.REACT_APP_SERVER_LOCAL==="true") {
        if (data.attributes.driverImg) {
          // If driverImg is an imageName, call this.getImage and wait for the result
          try {
              driverImg = await this.getImage(data.attributes.driverImg);
          } catch (error) {
              console.error('Error getting image======', error);
              driverImg = ''; // Set trailerImg to empty string in case of error
          }
      }
      }
      else{
        if (data.attributes.driverImg) {
            driverImg = data.attributes.driverImg; // Use the URL directly
        }
      }

        let formData = {
          id:data.id,
          name: data.name,
          uniqueId: data.uniqueId,
          tracker: data.tracker,
          expirationTime: data.expirationTime || '',
          identityNumber: data.attributes.identityNumber || '',
          dateOfBirthGregorian: data.attributes.dateOfBirthGregorian || '',
          WASLKey: data.attributes.WASLKey || '',
          driverAddress: data.attributes.driverAddress || '',
          email: data.attributes.email || '',
          licenseClass: data.attributes.licenseClass || '',
          licenseNumb: data.attributes.licenseNumb || '',
          licenseExpDate: data.attributes.licenseExpDate || '',
          phone: data.attributes.phone || '',
          department: data.attributes.department || '',
          driverImg: driverImg || ''
        }
        return formData
  }


  

  onCloseModal = () => {
    this.setState({
      isVisableTrackerModal: false,
      enableImagePopup: false
    })
  }
    imageDataPrepare = () => {
      const { imagesData, form } = this.state;

      if ( process.env.REACT_APP_SERVER_LOCAL==="true") {
        if (this.state.editOption) {
          this.ud_submit(form.id);
        } else {
          this.ud_submit();
        }
      } else {
        if (imagesData) {
          this.setState(
            {
              isVisibleBtn: true,
            })
          let url = `/api/uploads`

          instance({
            url: url,
            method: 'POST',
            headers: {
               Accept: 'application/json',
               'Content-Type': 'application/json'
            },
            data:{
              ...this.state.imagesData
            }
            })
        .then(response => {
          this.setState(
            {
              isVisibleBtn: false,
              uploadingImage:false,
              form: {
                ...this.state.form,
                driverImg: response.publicUrl ? response.publicUrl : ''
              }
            },
            () => {
              if (this.state.editOption) {

                  this.ud_submit(this.state.driverId)
                } else {
                  this.ud_submit()
                }
            }
          );
        })
        .catch(error => {
          console.error('Error in imageDataPrepare:', error);
          // Handle the error, display a message, etc.
        });
      } else {
      if (this.state.editOption) {
        this.ud_submit(form.id);
      } else {
        this.ud_submit();
      }
      }
}
  };


uploadImage = async(event) => {
  let files = event.target.files
  const { form } = this.state;

  let imgData = ''
  let reader = new FileReader()
  if (files && files[0]) {
    if(process.env.REACT_APP_SERVER_LOCAL==="true" && form.id){
      const response = await fetch(`/api/uploads/driver/${form.id}`, {
        method: 'POST',
        body: files[0],
      });
      if (response.ok) {
        const responseData = await response.json(); // Parse JSON response
         const imageName = responseData.data.image;
        this.setState(
          {
            imagesData:  URL.createObjectURL(files[0]),
            form: {
              ...this.state.form,
              // driverImg: URL.createObjectURL(files[0]) ,// Use URL.createObjectURL to display the image
              driverImg: imageName ,
              attributes:{
                ...this.state.form.attributes,
                driverImg:imageName
              }
            }
          },
          () => {
            let { name, uniqueId } = this.state.form;
            if (name && uniqueId) {
              this.setState({
                uploadingImage:true,

                isVisableUserBtn: true
              });
            } else {
              this.setState({
                 uploadingImage:true,

                isVisableUserBtn: this.state.isAttChanged
              });
            }
          }
        );

      } else {
        throw Error(await response.text());
      }
    }
  else{
    reader.readAsDataURL(files[0])
    reader.onload = e => {
      imgData = e.target.result
      if (imgData.split(':')[1].split('/')[0] === 'image') {
        let obj = {
          name: `${this.state.form.uniqueId + new Date().getTime()}`,
          contentType: `${files[0].type}`,
          oldUrl: `${this.state.form.driverImg}`,
          // oldUrl: this.state.form.driverImg ? this.state.form.driverImg :"",

          data: `${imgData.split('base64')[1].substring(1)}`
        }
        this.setState(
          {
            imagesData: obj,
            form: {
              ...this.state.form,
              driverImg: imgData
            }
          },
          () => {
            let { name, uniqueId } = this.state.form
            if (name && uniqueId) {
              this.setState({
                isVisableUserBtn: true
              })
            } else {
              this.setState({
                isVisableUserBtn: this.state.isAttChanged
              })
            }
          }
        )
      } else {
        //this.props.enqueueSnackbar(this.props.translate('onlyAcceptImage'));
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate('onlyAcceptImage'),
            autoDismiss: 10
          })
        )
      }
    }
  }

  
  } else {
    this.setState(
      {
        imagesData: '',
        form: {
          ...this.state.form,
          driverImg: this.props.selecteditem.attributes
            ? this.props.selecteditem.attributes.driverImg
            : ''
        }
      },
      () => {
        this.setState({
          isVisableUserBtn: this.state.isAttChanged
        })
      }
    )
  }
}

  submitWasl = (form, driverResponse, data) => {
    const { waslCompanyRefKey } = this.props?.serversettings?.attributes || {};
    if(waslCompanyRefKey) {
      const { identityNumber, phone, dateOfBirthGregorian } = form?.attributes || {};
      if(identityNumber && phone && dateOfBirthGregorian) {
        const formData = {
          identityNumber,
          phone,
          activity: 'DEFAULT'
        }
        if(form.id) {
          formData.dateOfBirthGregorian = dateOfBirthGregorian
        }
        axios.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, { 
          hook: 'drivers',
          account_id: this.props.serversettings.id,
          data: JSON.stringify(formData),
          hook_prefix: waslCompanyRefKey
        }).then(response => {
          if(response.status === 200 && response.data && response.data.success) {
            if(response.data.success) {
              this.setState({
                waslStatus: <span className="loading-status-text"><DoneAllIcon className="status-icon" /> Done</span>,
              })
              if(response.data.result && response.data.result.referenceKey) {
                this.handleChange('WASLKey')({ target: { value: response.data.result.referenceKey}});
                this.ud_submit(form.id, true);
              }
              else {
                this.handleAfterResponse(driverResponse, data)
              }
            }
          }    
        }).catch(error => {
          this.setState({
            waslStatus: <span className="loading-status-text"><ErrorOutlineIcon className="status-icon" /> invalid form</span>,
          })
          let res = error?.response?.data || {}
          let check = res&&res.data&&res.data.result
          if(check&& check.referenceKey && check.isValid){
                this.handleChange('WASLKey')({ target: { value: check.referenceKey}});
                this.ud_submit(form.id, true);
          }
          this.handleAfterResponse(driverResponse, data)
          console.warn(error);
        })
      }
      else {
        this.setState({
          waslStatus: <span className="loading-status-text"><ErrorOutlineIcon className="status-icon" /> incomplete form</span>,
        })
        this.handleAfterResponse(driverResponse, data)
      }
    }
    else {
      this.handleAfterResponse(driverResponse, data)
    }
  } 

  handleChange = name => event => {
    const { target } = event
    let value = "";
    if (name === 'licenseExpDate' || name === 'dateOfBirthGregorian' || name === 'expirationTime') {
      value = event.toISOString();
    } else {
      value = target.value;
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: value
        }
      },
      () => this.checkRequiredFields()
    )
  }


  checkRequiredFields () {
    let { name, uniqueId } = this.state.form
    let value = !isEqual(this.state.form, this.state.selectedDriver)
    if (name && uniqueId && value) {
      this.setState({
        isVisableUserBtn: true
      })
    } else {
      this.setState({
        isVisableUserBtn: this.state.isAttChanged
      })
    }
  }


  submitDataPepration = () => {
    const { form, itemAttributes } = this.state;
    const att = [
      "WASLKey", "dateOfBirthGregorian", "identityNumber", "tag_1", "tag_2", "tag_3", "tag_4", "tag_5", 
      "email", "licenseClass", "licenseNumb", "licenseExpDate", "phone", "department", "driverImg"
    ];
  
    const attributes = att.reduce((acc, key) => {
      if (form[key]) {
        acc[key] = form[key];
      }
      return acc;
    }, {});
  
    if (this.props.driverLat) {
      attributes.driverLat = this.props.driverLat;
    }
  
    if (this.props.driverLon) {
      attributes.driverLon = this.props.driverLon;
    }
  
    if (form.driverAddress) {
      attributes.driverAddress = form.driverAddress
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    }
  
    if (form.driverImg) {
      attributes.driverImg = form.driverImg
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    }
  

    const finalAttributes = { ...itemAttributes, ...attributes };
    return finalAttributes;
  };
  
  
  ud_submit = (driverId, disableWaslCall) => {
    const { waslEnabled } = this.props?.serversettings?.attributes || {};
    const id = driverId ? driverId : 0;
    const { form: { name, uniqueId, expirationTime } } = this.state;
  
    // Prepare data
    let preperData = { name, uniqueId, expirationTime };
    let attriubtes = this.submitDataPepration();
  
    // Format the object for the API call
    const obj = driverDataFormat(preperData, attriubtes, id, this.state.dr_attriubtes);
  
    // Remove unnecessary attributes
    delete obj.attributes.lat;
    delete obj.attributes.lng;
    delete obj.attributes.driverLngwaslStatus;
    if (!obj.attributes.WASLKey) {
      delete obj.attributes.WASLKey;
    }
  
    // Remove unused tags
    ['tag_1', 'tag_2', 'tag_3', 'tag_4', 'tag_5'].forEach(tag => {
      if (!obj.attributes[tag]) {
        delete obj.attributes[tag];
      }
    });
  
    // API call setup
    const data = {
      id,
      url: id ? `/api/drivers/${id}` : `/api/drivers/`,
      method: id ? 'PUT' : 'POST',
      successMsg: id ? 'driverIsUpdated' : 'driverIsCreated',
    };
  
    // Set loading state and make the API request
    this.setState({ isLoading: true }, () => {
      instance({
        url: data.url,
        method: data.method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: {
          ...obj,
        }
      })
        .then(driver => {
          // Handle the response
          this.setState({ isLoading: false });
  
          if (!disableWaslCall && waslEnabled) {
            this.submitWasl(obj, driver, data);
          } else {
            this.handleAfterResponse(driver, data);
          }
        })
        .catch(error => {
          // Handle error and reset loading state
          this.setState({ isLoading: false });
          errorHandler(error, this.props.dispatch);
        });
    });
  }
  
  
  handleAfterResponse = (response, data) => {
    if(data.id){
      this.props.dispatch(updateDriver(response))
    }else{
      this.props.dispatch(addDriver(response))
    }
    this.props.onCloseModal()
    this.props.fetchMoreItems()
    this.setState({
      itemAttributes: response.attributes,
      dr_attriubtes: response.attributes,
      dm_isVisable: !this.state.dm_isVisable,
      selectedDriver: response,
      attributeChangesMessage: '',
      isVisableUserBtn: false,
      isAttChanged: false,
      imagesData: '',
      driverStatus: <span className="loading-status-text"><DoneAllIcon className="status-icon" /> Done</span>,
    },
      this.props.dispatch(
        Notifications.success({
          message: this.props.translate(data.successMsg),
          autoDismiss: 10
        })
      )
    )
  }

  onCloseSubModal () {
    this.setState({
      dm_isVisable: false,
      addOption: false,
      editOption: false
    })
    this.props.onCloseModal()
  }

  changeAttribute (name, value, selectedOption, updateAtt) {
    if (selectedOption === 'updateAtt' || selectedOption === 'deleteAtt') {
      this.setState(
        {
          dr_attriubtes: { ...updateAtt }
        },
        () => this.checkForAttrubutesChanged(selectedOption)
      )
    } else {
      this.setState(
        {
          dr_attriubtes:selectedOption
          // dr_attriubtes: {
          //   ...this.state.selectedDriver.attributes,
          //   [name]: value
          // }
        },
        () => this.checkForAttrubutesChanged()
      )
    }
  }

  checkForAttrubutesChanged = selectedOption => {
    let changed = false
    if (this.state.selectedDriver) {
      changed = !isEqual(
        this.state.dr_attriubtes,
        this.state.selectedDriver.attributes
      )
    } else {
      changed = !this.isEmpty(this.state.dr_attriubtes)
    }
    let { name, uniqueId } = this.state.form
    let selectedDriver = {}
    if ((name && uniqueId) || (name && uniqueId && changed)) {
      selectedDriver = this.state.dr_attriubtes
      if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
        this.setState({
          isVisableUserBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...selectedDriver }
        })
      } else {
        this.setState({
          isVisableUserBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...this.state.itemAttributes, ...selectedDriver }
        })
      }
    } else {
      selectedDriver = this.state.dr_attriubtes
      if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
        this.setState(
          {
            isVisableUserBtn: false,
            isAttChanged: false,
            itemAttributes: { ...selectedDriver }
          },
          () => this.checkRequiredFields()
        )
      } else {
        this.setState(
          {
            isVisableUserBtn: false,
            isAttChanged: false,
            itemAttributes: { ...this.state.itemAttributes, ...selectedDriver }
          },
          () => this.checkRequiredFields()
        )
      }
    }
    if (changed) {
      this.setState({
        attributeChangesMessage: 'Change in attributes is pending'
      })
    } else {
      this.setState(
        {
          attributeChangesMessage: ''
        },
        () => this.checkRequiredFields()
      )
    }
  }
  isEmpty = obj => {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
    }
    return true
  }

 
  setExpirationTime = (e,name) => {
    e.stopPropagation()
    if(name === 'licenseExpDate'){
      this.setState(
        {
          form: {
            ...this.state.form,
              licenseExpDate: null
          }
        },
        () => this.checkRequiredFields()
      )
    }else if(name === 'expirationTime'){
      this.setState(
        {
          form: {
            ...this.state.form,
            expirationTime: null
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }

  deleteWASLKey = () => {
    const id = this.state.driverId ? this.state.driverId : 0;
    const { form: { name, uniqueId, expirationTime } } = this.state
    let preperData = { name, uniqueId, expirationTime }
    let attriubtes = this.submitDataPepration()
    const obj = driverDataFormat(preperData, attriubtes, id)
    delete obj.attributes.WASLKey;
    const data = {
      id,
      url: id ? `/api/drivers/${id}` : `/api/drivers/`,
      method: id ? `PUT` : `POST`,
      successMsg: id ? `driverIsUpdated` : `driverIsCreated`,
    };

    fetch(data.url, {
      method: data.method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...obj
      })
    })
    .then(response => {
      if (response.ok) {
        response.json().then(driver => {
          this.handleAfterResponse(driver, data);
        })
      } else {
        throw response
      }
    })
  }

  
  imagePopup = () => {
    this.setState({
      enableImagePopup: true
    })
  }

  unit_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selecteddevices]
    let obj = {
      deviceId: item.id,
      driverId: this.props.selecteditem.id
    }
    if (value) {
      method = 'POST'
    }
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data :{
        ...obj
      }
    })
    .then(() => {
      // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateDeviceUserWiseSelectedList(item))
          this.props.dispatch(RemoveDeviceUserWise(item))
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('trackerIsUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateDeviceUserWiseSelectedList(item))
          this.props.dispatch(addDeviceUserWise(item))
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('trackerIsAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }

  render () {

    const { selecteddevices } = this.props
    return (
      <div style={{ height: '100%', position: 'relative', zIndex: 9999 }}>
        {this.state.dm_isVisable && this.state.addOption && (
          <AddDrivers
            onClose={this.onCloseSubModal}
            selectedDrivers={this.state.selectedDriver}
            formSubmit={this.imageDataPrepare}
            deleteWASLKey={this.deleteWASLKey}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            isVisibleBtnImage={this.state.isVisibleBtn}
            form={this.state.form}
            buttonText='sharedCreate'
            translate={this.props.translate}
            isVisableUserBtn={this.state.isVisableUserBtn}
            itemAttributes={this.state.itemAttributes}
            attributeChangesMessage={this.state.attributeChangesMessage}
            setExpirationTime={this.setExpirationTime}
            isHaveAccess='driverCreate'
            uploadImage={this.uploadImage}
            imagePopup={this.imagePopup}
            isLoading={this.state.isLoading}

          />
        )}
        {this.state.selectedDriver && this.state.editOption && (
          <AddDrivers
            onClose={this.onCloseSubModal}
            selectedDrivers={this.state.selectedDriver}
            formSubmit={this.imageDataPrepare}
            deleteWASLKey={this.deleteWASLKey}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            form={this.state.form}
            isVisibleBtnImage={this.state.isVisibleBtn}

            imagesData={this.state.imagesData}
            uploadingImage={this.state.uploadingImage}
            buttonText='update'
            translate={this.props.translate}
            isVisableUserBtn={this.state.isVisableUserBtn}
            itemAttributes={this.state.itemAttributes}
            attributeChangesMessage={this.state.attributeChangesMessage}
            setExpirationTime={this.setExpirationTime}
            serversettings={this.props.serversettings}
            isHaveAccess='driverUpdate'
            uploadImage={this.uploadImage}
            imagePopup={this.imagePopup}
            editOption
            isLoading={this.state.isLoading}

          />
        )}
        {this.state.selectedDriver &&
        this.props.showItemDetail &&
        this.props.trackersApiResponce ? (
          <EditItem
            devices={this.props.devices}
            currentLocation={this.props.currentLocation}
            selectedItem={this.state.selectedDriver}
            handleClose={this.onCloseSubModal}
            devicesIcons={this.props.devicesIcons}
            translate={this.props.translate}
          />
        ) : !this.state.addOption && !this.state.editOption ? (
          <Loader />
        ) : null}
        {this.state.isVisableTrackerModal && (
          <CustomDialog
            themecolors={this.props.themecolors}
            visable
            title={this.props.translate('deviceTitle')}
            onClose={this.onCloseModal}
            isButtonVisable
            bodyPadding={0}
          >
            <div style={{ minHeight: 200 }}>
              <CommonTableSection
                component_Selection={this.unit_Selection}
                themecolors={this.props.themecolors}
                componentData={selecteddevices}
                showCheckbox
                onDelete={this.onRemovedDevice}
                label={this.props.translate('sensorUnits')}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                rowsPerPage={this.state.rowsPerPage}
                canUpdate={false}
                rowDefinition={[
                  {
                    id: 'label',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('sensorUnits')
                  }
                ]}
                canDelete={checkPrivileges('deviceDelete')}
                canRemove={checkPrivileges('deviceLinkDriver')}
                canAssign={checkPrivileges('deviceUnlinkDriver')}
                selectAll={e =>
                  selectAll(
                    e,
                    'deviceId',
                    'driverId',
                    this.props.selecteditem.id,
                    this.props.selecteddevices,
                    this.props.devices2,
                    this.props.dispatch,
                    'deviceAlt',
                    this.props.translate
                  )
                }
              />
            </div>
          </CustomDialog>
        )}
        {this.state.enableImagePopup && this.state.form.driverImg && (
          <CustomDialog
            themecolors={this.props.themecolors}
            visable={true}
            onClose={this.onCloseModal}
            bodyPadding={10}
            cancelText={this.props.translate('sharedCancel')}
            noFullScreen
          >
            <div style={{ minHeight: 200, margin: 0, textAlign: 'center' }}>
              <img
                style={{ width: 500, height: 400 }}
                alt='driverImage'
                src={this.state.form.driverImg}
              />
            </div>
          </CustomDialog>
        )}
      </div>
    )
  }
}

const mapState = state => ({
  devices2: state.devices2,
  drivers: state.drivers,
  devices: state.devices.data,
  themecolors: state.themeColors,
  selecteddevices: state.selecteddevices,
  serversettings: state.ServerSetting,
  // devices:state.devices
})
const mapStateToProps = connect(mapState)
export const DriversModal = mapStateToProps(
  (withLocalize(driversModal))
)
