import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import Layout from '../../Layout'
import {  removedvehicle } from '../../Actions/Vehicles'
import Grid from '@mui/material/Grid'
import { VehicleModal } from '../../Components/Vehicles/vehicleModal'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import { checkPrivileges, errorHandler } from '../../Helpers/index'
// import CustomMarker from '../../Components/Maps/CustomMarker'
import { MapTooltip } from '../../Components/Maps/MapTooltip'
import axios from 'axios'
import moment from 'moment'
import {
  MapContainer,
  TileLayer,
  Tooltip,
  ZoomControl,
  LayersControl,
  Marker
} from 'react-leaflet'

import { MapTileLayers } from '../../Reducers/Maps'
import L from 'leaflet'
import Style from 'style-it'
import { setTrackId } from '../../Actions/Devices'
import withResources from '../HOCRecources'
import ResourceModal from '../../Components/Recources/resourceModal'
import Notifications from 'react-notification-system-redux'
import instance from '../../axios'

function importAll (r) {
  return r.keys().map(r)
}

const images = importAll(
  require.context('./../../images', false, /\.(png|jpe?g|svg)$/)
)
const CancelToken = axios.CancelToken
let source
// class vehicle extends Component {
const vehicle = (props) => {
  // constructor (props) {
  //   super(props)
  //   state = {
  const [state, setState] = useState({
      selecteditem: '',
      isVisable: false,
      showItemDetail: false,
      selecteItemUrl: '',
      activeOperation: '',
      vehicleAddress: '',
      vehicleLat: '',
      vehicleLon: '',
      selecteditemId: props.match.params.id,
      trackersApiResponce: false,
      trackerData: '',
      assignVehicle: false,
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      // lat: 0,
      // lng: 0,
      // zoom: 3,
      minZoom: 3,
      animCount: 0,
      assigned: false,
      tracking: false,
      applied: false,
      resourceList: false,
      allVehicleLocation: {},
      showWaslShortList:false,
      sequenceNumber:'',
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: props.deviceId,
        hasNext: true,
        searchText: ''
      },
      areaList:[],
      userVehicles:[],
      page: 1,
      hasMore: true,
      searchVehicle: '',
      lat: props.logInUser.latitude || 0,
      lng: props.logInUser.longitude || 0,
      zoom: props.ServerSetting.zoom || props.logInUser.zoom || 10,

    })
const [trailerId,setTrailerId]=useState(0)

  //   source = CancelToken.source()
  // }

    useEffect(() => {
      if (props.ServerSetting.zoom) {
        setState((prevState)=>({
          ...prevState,
          zoom: props.ServerSetting.zoom,
          lat: props.ServerSetting.latitude,
          lng: props.ServerSetting.longitude
        }))
      }
      if (props.logInUser.zoom) {
        setState((prevState)=>({
          ...prevState,
          zoom: props.logInUser.zoom,
          lat: props.logInUser.latitude,
          lng: props.logInUser.longitude
        }))
      }
      if (props.logInUser && props.logInUser.id && state.initFetch === false) {
        setState((prevState)=>({
          ...prevState,
            initFetch: true
          }
        ))
      }
      if (!props.match.params.id && props.match.params.id) {
        setState((prevState)=>({
          ...prevState, selecteditemId: props.match.params.id }))
      }
  
      if(props.trackId !== props.trackId) {
        setState((prevState)=>({
          ...prevState,
          // allVehicleLocation: {},
          animCount: 0
        }))
          calculate(props);
      }
      else {
        calculate(props);
      }
      if(props.match.params.id && state.userVehicles.length && (props.match.url !== state.selecteItemUrl)){
        if(source) {
          source.cancel();      
        }
       selectedItem(props.match.params.id)
      }
    }, [props.ServerSetting.zoom,
        props.logInUser.zoom,
        state.initFetch,
        props.match.params.id,
        props.trackId])
    

  // componentWillReceiveProps(n) {
  //   if (n.ServerSetting.zoom) {
  //     setState({
  //       zoom: n.ServerSetting.zoom,
  //       lat: n.ServerSetting.latitude,
  //       lng: n.ServerSetting.longitude
  //     })
  //   }
  //   if (n.logInUser.zoom) {
  //     setState({
  //       zoom: n.logInUser.zoom,
  //       lat: n.logInUser.latitude,
  //       lng: n.logInUser.longitude
  //     })
  //   }
  //   if (n.logInUser && n.logInUser.id && state.initFetch === false) {
  //     setState(
  //       {
  //         initFetch: true
  //       },
  //       () => {
  //         // fetchData(
  //         //   n.logInUser.id,
  //         //   state.currentPage,
  //         //   state.pagesize
  //         // )
  //       }
  //     )
  //   }
  //   if (!n.match.params.id && props.match.params.id) {
  //     setState({ selecteditemId: props.match.params.id })
  //   }

  //   if(props.trackId !== n.trackId) {
  //     setState({allVehicleLocation: {}, animCount: 0}, () => {
  //      calculate(n);
  //     })
  //   }
  //   else {
  //     calculate(n);
  //   }
  //   if(n.match.params.id && n.vehicles.length && (n.match.url !== state.selecteItemUrl)){
  //     if(source) {
  //       source.cancel();      
  //     }
  //    selectedItem(n.match.params.id)
  //   }
  // }

  useEffect(() => {
    if (
      !state.assignVehicle &&
      state.userVehicles &&
      props.devices &&
      props.devices.length > 0 &&
      state.itemPagination &&
      state.itemPagination.total > 0
    ) {
      setState(prevState => ({ ...prevState, assignVehicle: true }));
    }
  }, [state.userVehicles, props.devices, state.itemPagination, state.assignVehicle]);
  
  useEffect(() => {
    if (state.assignVehicle) {
      const selecteditem = state.userVehicles.find(
        v => '' + v.id === '' + state.selecteditemId
      );
      if (selecteditem && props.renderMode === 'edit') {
        editItem(selecteditem);
      }
    }
  }, [state.assignVehicle, state.userVehicles, state.selecteditemId, props.renderMode]);
  
  useEffect(() => {
    if (props.logInUser && props.logInUser.latitude && props.logInUser.longitude && !state.assigned) {
      setState(prevState => ({
        ...prevState,
        assigned: true,
        lat: props.logInUser.latitude,
        lng: props.logInUser.longitude
      }));
    }
  }, [props.logInUser, state.assigned]);
  

  useEffect(() => {
    // Setting state based on ServerSetting and logInUser props
    if (props.ServerSetting && props.ServerSetting.zoom) {
      setState(prevState => ({
        ...prevState,
        zoom: props.ServerSetting.zoom,
        lat: props.ServerSetting.latitude,
        lng: props.ServerSetting.longitude
      }));
    }
  
    if (props.logInUser && props.logInUser.zoom) {
      setState(prevState => ({
        ...prevState,
        zoom: props.logInUser.zoom,
        lat: props.logInUser.latitude,
        lng: props.logInUser.longitude
      }));
    }
  
    // Setting selecteditemId from URL params
    if (props.match.params.id) {
      setState(prevState => ({ ...prevState, selecteditemId: props.match.params.id }));
    }
  
    // Fetching and selecting item based on URL params and vehicles data
    if (props.match.params.id && state.userVehicles?.length && (props.match.url !== state.selecteItemUrl)) {
      if (source) {
        source.cancel();
      }
      selectedItem(props.match.params.id);
    }
  
    // Handling deviceRelatedData and vehicles mapping
    // let trailerUniqueId = 0;
    // if (props.deviceRelatedData && Object.values(props.deviceRelatedData).length) {
    //   const list = {};
    //   const ids = state.userVehicles?.map(d => {
    //     list[d.deviceId] = d;
    //     if (d.id === parseInt(props.match.params.id)) {
    //       trailerUniqueId = d.id;
    //     }
    //     return d.deviceId;
    //   });
  
    //   const vehicles = {};
    //   Object.values(props.deviceRelatedData).forEach(d => {
    //     if (ids.includes(d.id)) {
    //       vehicles[d.id] = d;
    //       vehicles[d.id].vehicle = list[d.id];
    //     }
    //   });
    //   console.log("vehicles",vehicles)
  
    //   setState(prevState => ({ ...prevState, allVehicleLocation: vehicles }));
    // }
  
    // Setting vehicleLocation and updating map if conditions are met
    if (state.showItemDetail && props.deviceRelatedData && 
      Object.values(props.deviceRelatedData).length && props.match
       && props.match.params && props.match.params.id && 
       (props.renderMode === 'view')) {
      const vehicleLocation = Object.values(props.deviceRelatedData).find(({ id }) => trailerId && parseInt(id) === parseInt(trailerId));
      if (vehicleLocation && vehicleLocation.latitude && vehicleLocation.longitude) {
        setState(prevState => ({ ...prevState, vehicleLocation: vehicleLocation || null }));
        if (vehicleLocation && state.tracking === true) {
          mapRef.current && mapRef.current.setMaxZoom(16).fitBounds([[vehicleLocation.latitude, vehicleLocation.longitude]]);
          setTimeout(() => {
            mapRef.current && mapRef.current.setMaxZoom(props.mapLayer.maxZoom);
            setState(prevState => ({ ...prevState, applied: true }));
          }, 100);
        }
      }
    }
  
    // Fetching area list if user has area privileges
    // if (checkPrivileges('area')) {
    //   getAreaList();
    // }
  }, []);
  useEffect(()=>{
    if (props.deviceRelatedData && Object.values(props.deviceRelatedData).length) {
      const list = {};
    let trailerUniqueId = 0;
      const ids = state.userVehicles?.map(d => {
        list[d.deviceId] = d;
        if (d.id === parseInt(props.match.params.id)) {
          trailerUniqueId = d.id;
        }
        return d.deviceId;
      });
  
      const vehicles = {};
      Object.values(props.deviceRelatedData).forEach(d => {
        if (ids.includes(d.id)) {
          vehicles[d.id] = d;
          vehicles[d.id].vehicle = list[d.id];
        }
      });

      setTrailerId(trailerUniqueId)
      setState(prevState => ({ ...prevState, allVehicleLocation: vehicles }));
    }
  },[props.deviceRelatedData,state.userVehicles,props.match.params.id])

  useEffect(() => {
    return () => {
      if (source) {
        source.cancel();
      }
  
      setState({
        selecteditem: '',
        isVisable: false,
        showItemDetail: false,
        activeOperation: '',
        vehicleAddress: '',
        vehicleLat: '',
        vehicleLon: '',
        selecteditemId: '',
        trackersApiResponce: false,
        multiTrackers: '',
        initFetch: false,
        currentPage: 1,
        pagesize: 50,
        animCount: 0,
        allVehicleLocation: {},
        itemPagination: {
          items: [],
          total: 0,
          currentPage: 0,
          currentDevice: props.deviceId,
          hasNext: true,
          searchText: ''
        }
      });
    };
  }, []);
  
  


  const checkZoom = () => {
    if(state.applied === true) {
      setState((prevState)=>({
        ...prevState,
        tracking: false, applied: false, animCount: 0 
      }))
        props.dispatch(setTrackId(0))

        setState((prevState)=>({
          ...prevState,allTrailerLocation: {}
        }))
          calculate(props)
    }
  }

  const calculate = n => {
    // if(n.deviceRelatedData && Object.values(n.deviceRelatedData).length) {
    //   const list = {};
    //   const ids =  state.userVehicles.map(d => { 
    //     list[d.deviceId] = d;
    //     return d.deviceId
    //   })
    //   const vehicles = {};
    //   Object.values(n.deviceRelatedData).map(d => {
    //     if(ids.includes(d.id)) {
    //       vehicles[d.id] = d;
    //      vehicles[d.id].vehicle = list[d.id];
    //     }
    //     return null
    //   })
    //   setState((prevState)=>({
    //     ...prevState,
    //     allVehicleLocation: vehicles}))  
    // }
    
    if(state.showItemDetail && state.userVehicles?.length && n.deviceRelatedData && Object.values(n.deviceRelatedData).length && n.match && n.match.params && n.match.params.id && (props.renderMode === 'view')) {
      const vehicleLocation = Object.values(n.deviceRelatedData).find(({ vehicle}) => vehicle && parseInt(vehicle.id) === parseInt(n.match.params.id))
      if(vehicleLocation && vehicleLocation.latitude && vehicleLocation.longitude) {
        setState((prevState)=>({
          ...prevState,
          vehicleLocation: vehicleLocation || null}
        
      ))
        if(vehicleLocation && state.tracking === true) {
          mapRef.current?.setMaxZoom(16).fitBounds([[vehicleLocation.latitude, vehicleLocation.longitude]])
            setTimeout(() => {
              mapRef.current?.setMaxZoom(n.mapLayer.maxZoom)
              setState((prevState)=>({
                ...prevState,
                applied: true, 
                animCount: 1}))
            }, 200)
      }
      }
    }
  }

  const getUserVehicles = ()=>{
    if (source) {
      source.cancel('Operation canceled due to new request=======.');
    }
    source = axios.CancelToken.source();

    instance({
      url: `/api/vehicles/get`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params:{
        userId: props.logInUser.id,
        all: true,
        limit: 0,
        page:state.page,
        search:state.searchVehicle
      },
      cancelToken: source.token,
    })
    // Axios.get(url, { cancelToken: source.token })
      .then(response => {
        // console.log("Res=====", this.state.userVehicles);
        const newVehicles = response.data;
        const total = response.total;
        // this.setState({userVehicles : response.data})
        setState((prevState)=>{
          const allData=[...prevState.userVehicles,...newVehicles]
          return {
            ...prevState,
            userVehicles:allData,
            page: prevState.page + 1,
            hasMore: prevState.userVehicles.length + newVehicles.length < total,
          }
        })
      })
      .catch(() => {});
  }
useEffect(()=>{
getUserVehicles()
},[state.searchVehicle])

 const  searchItems = text => {

    setState((prevState)=>({
      ...prevState,
      searchVehicle: text,
      page:1,
      userVehicles:[]
    }))
  }

  const fetchMoreItems = () => {
    // fetchData(
    //   props.logInUser.id,
    //   state.currentPage,
    //   state.pagesize
    // )
  }

  // const removeDuplicates = (n, o) => {
  //   let items = []
  //   o.map(j => {
  //     let found = n.data.find(e => e.id === j.id)
  //     if (found) {
  //       items.push(found)
  //     }
  //     return null
  //   })

  //   if (!items.length) {
  //     setState((prevState)=>({
  //       ...prevState,
  //         itemPagination: {
  //           ...n,
  //           items: o.concat(n.data)
  //         },
  //         currentPage: n.hasNext ? n.page + 1 : n.page
  //       }
  //     ))
  //     props.dispatch(getVehicles(o.concat(n.data)))

  //   }
  // }

  // const fetchData = (userId, page, perPage, reset) => {
  //   const query = 'all=true&userId=' + userId
  //   let searchText = state.searchText

  //   if (searchText) {
  //     searchText = '&search=' + searchText
  //   } else {
  //     searchText = ''
  //   }
  //   let items = state.itemPagination.items
  //   if (reset) {
  //     setState(
  //       {
  //         itemPagination: { ...state.itemPagination, items: [] }
  //       },
  //       () => {
  //         items = []
  //       }
  //     )
  //   }

  //   instance({
  //     method: 'GET',
  //     url: `/api/vehicles/get?${query}&page=${page}&limit=${perPage}${searchText}`,
  //     cancelToken: source.token,
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     }
  //   }).then(response => {
  //     // if (response.status === 200) {
  //       // if (response.data.status === 'success') {
  //         removeDuplicates(response, items)
  //       // } else {
  //         //something went wrong
  //       // }
  //     // }
  //   }).catch(error => {
  //     console.error(error);
  //     // errorHandler(error,props.dispatch)
  //   })
  // }

 const  addItem = () => {
  setState((prevState)=>({
    ...prevState,
        isVisable: true,
        showItemDetail: false,
        selecteditem: '',
        trackerData: '',
        activeOperation: 'add'
      }
    ));
    props.history.push('/vehicles')
  }

 const  showResources = (type) => {
  setState((prevState)=>({
    ...prevState,
      resourceList: type
    }))
  }


 const  addResource = () =>{
  setState((prevState)=>({
    ...prevState,
      isVisable: true,
      showItemDetail: false,
      activeOperation: 'addResource',
      selecteditem: '',
      driverAddress: '',
      driverLat: '',
      driverLon: ''
    }))
  }

  const onEditResource = (item) =>{
    setState((prevState)=>({
      ...prevState,
      isVisable: true,
      showItemDetail: false,
      activeOperation: 'editResource',
      selecteditem: item,
      driverAddress: '',
      driverLat: '',
      driverLon: ''
    }))
  }

 const  onLinkResource = (item) =>{
  setState((prevState)=>({
    ...prevState,
      linkResource: true,
      selecteditem: item,
    }))

    props.fetchNestedItems(item.id,1)
  }
  const [parentId,setParentId]=useState(0)
  const [isDeletedSuccessChild,setisDeleteSucessChild]=useState(false)

 const getParentIdFromListRow=(id)=>{
setParentId(id)
 }
 useEffect(()=>{
  if(isDeletedSuccessChild){
    if(parentId!==0){
      props.fetchNestedItems(parentId)
setParentId(0)
    }
    setisDeleteSucessChild(false)
  }
 },[isDeletedSuccessChild,parentId])

 const  onCloseResource = () =>{
  setState((prevState)=>({
    ...prevState,
      linkResource: false,
    }));
    onCloseModal();
  }

 const  removedItem = (item) => {
    // fetch(`/api/vehicles/${item.id}`, {
    //   method: 'DELETE',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/vehicles/${item.id}`,
      method: 'DELETE',
    })
    // .then(response => {
    //   if (response.ok) {
    //     response.json()
        .then(data => {
          if (data) {
            props.dispatch(removedvehicle(item))
            onCloseModal()
            setState((prevState)=>({
              ...prevState,
              selectedVehicle: ''
            }))
            setState((prevState) => {
              const updatedUserVehicles = prevState.userVehicles?.filter(vehicle => vehicle?.id !==item?.id);
              return {
                ...prevState,
                userVehicles: updatedUserVehicles
              };
            });
            setisDeleteSucessChild(true)

            props.dispatch(
              Notifications.success({
                message: props.translate('vehicleIsDeleted'),
                autoDismiss: 10
              })
            )
          } 
         // else {
          //   props.dispatch(
          //     Notifications.success({
          //       message: data.message,
          //       autoDismiss: 10
          //     })
          //   )
          // }
      //   })
      // } else {
      //   throw response
      // }
    }).catch(error => {errorHandler(error,props.dispatch)})
  }

 const  getSelectedDevice = trailerUniqueId => {
    if (trailerUniqueId) {

      const device = props.devices.find(d => d.id === trailerUniqueId)
      if (device) {
        setState((prevState)=>({
          ...prevState,
          trackerData: { value: device.id, label: device.name, uniqueId: device.uniqueId },
        }))
      } else {
        setState((prevState)=>({
          ...prevState,
          trackerData: '',
        }))
      }



      // if(this.map && props.deviceRelatedData && Object.values(props.deviceRelatedData).length) {
        if( props.deviceRelatedData && Object.values(props.deviceRelatedData).length) {
        const vehicleLocation = Object.values(props.deviceRelatedData).find(({id}) => id === trailerUniqueId)
        setState((prevState)=>({
          ...prevState,
           vehicleLocation: vehicleLocation && vehicleLocation.exists ? vehicleLocation : null,
           animCount: 0
          }))

        if(vehicleLocation && vehicleLocation.exists) {
          props.dispatch(setTrackId(vehicleLocation.id));
        }
        else {
          props.dispatch(setTrackId(0));
          // this.map.setZoom(3);
        }
      }
    }
    setState((prevState)=>({
      ...prevState,
      trackersApiResponce: true
    }))

  }

 const  editItem = (item) => {
    // await onCloseResource()
    if (item.deviceId) {
      setState((prevState)=>({
        ...prevState,
          trackersApiResponce: false
        }
      ))
      getSelectedDevice(item.deviceId)
    } else {
      setState((prevState)=>({
        ...prevState,
        trackersApiResponce: true
      }))
    }

    setState((prevState)=>({
      ...prevState,
      isVisable: true,
      showItemDetail: false,
      selecteditem: item,
      activeOperation: 'edit',
      trackerData: ''
    }))
    props.history.push('/vehicles/'+item.id)
  }

 const  showWaslDetails = (item)=>{
  setState((prevState)=>({
    ...prevState,
         showItemDetail: false,
         isVisable: false,
         onDeleteConfirmation:false,
         showWaslShortList:false,
         vehicleHookData:''
    }))
     getWaslDetails(item)
  }

 const  getWaslDetails= (item)=>{
    if(item.attributes.sequenceNumber){
      axios.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, { 
        "hook":"getVehicle",
        "account_id":props.logInUser&&props.logInUser.accountId,
        "data":{
          "sequenceNumber":`${item.attributes && item.attributes.sequenceNumber}`,
          "activity":"DEFAULT"
        }
      })
      .then(res=>{
        if(res&&res.data&&res.data.success){
          setState((prevState)=>({
            ...prevState,
                vehicleHookData: res.data,
                sequenceNumber:item.attributes && item.attributes.sequenceNumber,
                showWaslShortList:true
            }))
        }

      })
      .catch(err=>{
        if(err&&err.response && err.response.data&&err.response.data.data&&err.response.data.data.errorMsg){
          props.dispatch(
            Notifications.error({
              message: err.response.data.data.errorMsg,
              autoDismiss: 10
            })
          )
        }
        if(err&&err.response && err.response.data&&err.response.data.data&&err.response.data.data.resultCode){
          props.dispatch(
            Notifications.error({
              message: 'Vehicle Not Found',
              autoDismiss: 10
            })
          )
        }
      })
      
    }
    else{
      props.dispatch(
        Notifications.error({
          message: 'Sequence Number doesnot exists',
          autoDismiss: 10
        })
      )
    }
  }
 const  onCloseModal = () => {
  setState((prevState)=>({
    ...prevState,
      isVisable: false,
      showItemDetail: false,
      selecteditem: '',
      activeOperation: '',
      onDeleteConfirmation: false,
      showWaslShortList:false
    }))
    props.history.push('/vehicles')

  }
 const  selectedItem = async peramId => {
  setState((prevState)=>({
    ...prevState,
      selecteItemUrl: props.match.url,
      // showItemDetail: false,

    }))
    if(source){
      source.cancel()
    }
    source = CancelToken.source()
    try {

      const response = await instance({
        method: 'GET',
        url: `/api/vehicles/get?vehicleId=${peramId}`,
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      });
      // if (response.status === 'success') {
        const data = response && response && response.data
        data.map(item => {
          setState((prevState)=>({
            ...prevState,
            selecteditem: item,
            selecteditemId: item.id
          }))
          // console.log("props.renderMode====", props.renderMode);
            // if(props.renderMode === 'view'){
              selecteItem(item)
            // }else if(props.renderMode === 'edit'){
              // editItem(item)
            // }
        })
      // }
    } catch (error) {
      //  errorHandler(error,props.dispatch)
    }
  }

  useEffect(() => {
    if(state.showItemDetail){
      getSelectedDevice(state?.sItem?.deviceId)
      calculate(props);
    }

  }, [state.showItemDetail])
  
  const selecteItem = (item) => {
    // console.log("Item3213======", item);
    // await onCloseResource()
    props.dispatch(setTrackId(0))

    // setState((prevState)=>({
    //   ...prevState,
    //   allVehicleLocation: {}
    // }))

        // getSelectedDevice(item.deviceId)
        calculate(props);
  
        setState((prevState)=>({
          ...prevState,
      // allVehicleLocation: {},

      showItemDetail: true,
          isVisable: false,
          activeOperation: 'details',
          // trackersApiResponce: false,
          trackerData: '',
          multiTrackers: '',
          vehicleLocation: null,
          animCount: 0,
          tracking: true,
          applied: false,
          onDeleteConfirmation: false,
          sItem:item
        }));
        // getSelectedDevice(item.deviceId)
  }

 const  onCancel = () => {
  setState((prevState)=>({
    ...prevState,
      onDeleteConfirmation: false
    }))
  }

//  const  mapReference = el => {
//     if (el) {
//       this.map = el  
//     }
//   }

 const  onRemovedItem = item => {
  setState((prevState)=>({
    ...prevState,
      selecteditem: item,
      onDeleteConfirmation: true
    }))
  }

  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      // Access the map instance
      const mapInstance = mapRef.current;      // eslint-disable-line getter-return
      console.warn("in=====", mapInstance);
      // Perform any operations with the map instance here
    }
  }, [mapRef]);

  // render () {

    let crs = {}
    
    if (['yandexMap', 'yandexSat'].includes(props.mapLayer.id)) {
      crs = { crs: L.CRS.EPSG3395 }
    }

    const body = [
      <>
        <ZoomControl position={'bottomright'} />
      </>
    ]

    const position = [state.lat, state.lng]
    const thisMap = [
      <MapContainer
        key={1}
        ref={mapRef}
        onZoomAnim={checkZoom}
        zoomControl={false}
        bounds={
          state.bounds && state.bounds.length
            ? state.bounds
            : null
        }
        // boundsOptions={setBoundOptions}
        style={{ height: props.height, width: props.width }}
        center={position}
        zoom={state.zoom}
        minZoom={state.minZoom}
        maxZoom={props.mapLayer.maxZoom}
        maxNativeZoom={props.mapLayer.maxZoom}
        {...crs}
      >
        {state.pointer}
        {body}
        
        {state.allVehicleLocation && Object.keys(state.allVehicleLocation).length ? 
        
        Object.values(state.allVehicleLocation).map(row=> row && row.exists ?
           <Marker
              key={row.id}
              position={{ lat: row.latitude, lng: row.longitude, updated: moment(row.serverTime) }}
              rotationAngle={0}
              rotationOrigin='center'
              animationTime={state.animCount > 0 && state.applied === true & props.trackId === row.id ? row.animationTime : 0}
              icon={L.divIcon({
                iconUrl:
                  '/assets/category/default/' +
                  (row.category || 'default') +
                  'top.svg',
                iconSize: [50, 50],
                iconAnchor: [25, 25],
                tooltipAnchor: [0, -20],
                className: 'custom-marker',
                html: `<img
                  style="transform: rotate(${row.course}deg)"
                    src=
                      '/assets/category/default/${row.category ||
                        'default'}top.svg'
                    
                    alt=''
                  />`
              })}
              iconSize={[50, 50]}
            >
              <Tooltip direction={'top'}>
                <MapTooltip
                  themecolors={props.themecolors}
                  position={row}
                  device={row}
                  trailer={row.trailer}
                  trailer2={state.selecteditem}
                  logInUser={props.logInUser}
                  translate={props.translate}
                />
              </Tooltip>
            </Marker> : null)
            
             : null}
        <LayersControl position="bottomright">
          {MapTileLayers.map(layer => <LayersControl.BaseLayer id={layer.id} checked={layer.id === props.mapLayer.id} key={layer.id} name={layer.name}>
            <TileLayer {...layer} minZoom={state.minZoom} />    
          </LayersControl.BaseLayer>)}
        </LayersControl> 
      </MapContainer>
    ]

    if (checkPrivileges('vehicle')) {
      return (
        <>
          <Layout
            {...props}
            addItem={addItem}
            editItem={editItem}
            showWaslDetails={showWaslDetails}
            removedItem={onRemovedItem}
            selecteItem={selectedItem}
            fetchMoreItems={fetchMoreItems}
            classFromChildren={!state.isVisable ? 'no-padding' : 'has-padding'}
            itemPagination={{ ...state.itemPagination }}
            searchItems={searchItems}
            allVehicleLocation={state.allVehicleLocation}
            showResources={showResources}
            onEditResource={onEditResource}
            onLinkResource={onLinkResource}
            addResource={addResource}
            resourceList={state.resourceList}
            getParentIdFromListRow={getParentIdFromListRow}
            // searchVehicles={searchVehicles}
            searchVehicle={state.searchVehicle}
            hasMore={state.hasMore}
            userVehicles={state.userVehicles}
            getUserVehicles={getUserVehicles}
          >

            <Style>{`
              .leaflet-control-layers-toggle {
                  background: url('/assets/images/maps/layers.png') no-repeat center;
                } 
            `}</Style>
            {!state.isVisable ? <div>{['osm', ''].includes(props.mapLayer.id) ? thisMap : null}
              {['carto'].includes(props.mapLayer.id) ? thisMap : null}
              {['gccStreet'].includes(props.mapLayer.id) ? thisMap : null}
              {['googleTerrain'].includes(props.mapLayer.id)
                ? thisMap
                : null}
              {['googleSatellite'].includes(props.mapLayer.id)
                ? thisMap
                : null}
              {['googleHybrid'].includes(props.mapLayer.id) ? thisMap : null}
              {['googleRoad'].includes(props.mapLayer.id) ? thisMap : null}
              {['baidu'].includes(props.mapLayer.id) ? thisMap : null}
              {['yandexMap', 'yandexSat'].includes(props.mapLayer.id)
                ? thisMap
                : null}
                </div> : null}
                {/* {console.log("showItemDetail======",state.showItemDetail , state.showWaslShortList )} */}
              {(state.showItemDetail || state.showWaslShortList) && (
                <VehicleModal
                  onCloseModal={onCloseModal}
                  devicesIcons={images}
                  currentLocation={state.vehicleLocation}
                  devices={props.devices}
                  resourceList={state.resourceList}
                  getSelectedDevice={getSelectedDevice}
                  {...state}
                />
              )}
              <ResourceModal
                changeResource={props.changeResource}
                selectedResourse={state.selecteditem}
                activeOperation={state.activeOperation}
                onCloseResource={onCloseResource}
                // itemPagination={props.vehicles}
                itemPagination={state.userVehicles}
                assignItem={props.assignItem}
                unassignItem={props.unassignItem}
                fetchNestedItems={props.fetchNestedItems}
                nestedResources={props.nestedResources}
                translate={props.translate}
                editResource={state.editResource}
                linkResource={state.linkResource}
                themecolors={props.themecolors}
                itemType='Vehicle'
                title='sharedVehicle'
              />
                {/* {console.log("state.isVisable======",state.showItemDetail , state.isVisable, ['add', 'edit'].includes(state.activeOperation)  )} */}

              {!state.showItemDetail ? <div className='main-content-page'>
              <div style={{ background: props.themecolors.backgroundColor, color: props.themecolors.textColor, borderRadius: 6 }}>
                {state.isVisable && ['add', 'edit'].includes(state.activeOperation) && (
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <VehicleModal
                        resourceList={state.resourceList}
                        onCloseModal={onCloseModal}
                        getSelectedDevice={getSelectedDevice}
                        devices={props.devices}
                        {...state}
                      />
                    </Grid>
                  </Grid>
                )}
              </div>
            </div> : null}

            {state.onDeleteConfirmation && (
              <ConfirmDialoag
                onCancel={onCancel}
                onOk={() => removedItem(state.selecteditem)}
                title={props.translate('areYouWantToDelete')}
                children={state.selecteditem.label}
              />
            )}
          </Layout>
        </>
      )
    } else {
      return null
    }
  // }
}

const mapStateToProps = state => {
  return {
    ServerSetting: state.ServerSetting,
    logInUser: state.logInUsers,
    vehicles: state.vehicles,
    positions: state.positions,
    devices: state.devices3,
    themecolors: state.themeColors,
    deviceRelatedData: state.deviceRelatedData,
    trackId: state.trackId,
    mapLayer: state.mapLayer
  }
}
export default connect(mapStateToProps)(withLocalize(withResources(vehicle, 'Vehicle')))
