import React from 'react'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import Button from '../../../Components/common/Button'
import Notifications from 'react-notification-system-redux'
import { errorHandler } from '../../../Helpers'
import Axios from 'axios'
import Tooltip from '../../../Components/common/Tooltip'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { DatePicker, MuiPickersUtilsProvider , DateTimePicker } from 'material-ui-pickers'

import DateFnsUtils from "@date-io/date-fns";
import instance from '../../../axios'
import axios from 'axios'
import { Scrollbar } from 'react-scrollbars-custom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../../Layout/Loader'
import TimerOffIcon  from '@mui/icons-material/TimerOff'


const serverTimeZone = 'Asia/Riyadh'; // Set your server time zone here
const CancelToken = axios.CancelToken
let source

class ShareLocation extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedDate: '',
      to:'',
      from:'',
      formatedDate:'',
      token:'',
      showToken:false,
      isCustomSelected:false,
      disableSubmit : true,
      existingToken:this.props.device.locSharingToken,
      deviceHistory:[],
      apiLoading:false
    }
    this.myFunction = this.myFunction.bind(this);
  }

  showDates = (e, date) => {
    // const duration = moment.duration(moment(this.state.to).diff(this.state.from));
    // const hours = duration.asHours();
    let timezone = serverTimeZone;
  
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
  
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
  
    moment.tz.setDefault(timezone);
  
    // Set the "from" date to the current date and time
    const currentDate = moment();
    const formatedDate = {
      from: currentDate.toISOString(),
      to: moment(date.endDate.format('YYYY-MM-DD HH:mm')).toISOString(),
    };
  
    let timeFormat = 'HH:mm';
  
    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = 'hh:mm A';
    }
  
    this.setState(
      {
        to: formatedDate.to,
        from: formatedDate.from,
        selectedDate:
          moment(date.startDate).format('YYYY-MM-DD ' + timeFormat) +
          ' - ' +
          moment(date.endDate).format('YYYY-MM-DD ' + timeFormat),
        ...formatedDate,
      },
      () => this.checkReqFields()
    );
  };

  checkReqFields=()=>{
    const {selectedDate, to, from}=this.state;
    if(selectedDate &&  to && from){
      this.setState({disableSubmit : false})
    }else{
      this.setState({disableSubmit : true})
    }
  }


  submit = () => {
    const params = new URLSearchParams(); // Create a URLSearchParams object
  
    // Append deviceId and expiration as form parameters
    params.append('deviceId', this.props.id);
    params.append('expiration', this.state.to);
  
    Axios({
      url: '/api/devices/share',
      method: 'POST',
      data: params.toString(), // Convert params to a string and pass as the request body
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded', // Set content type to application/x-www-form-urlencoded
      },
    })
    .then(response => {
      if (response.data.statusCode === '200') {
        this.setState(
          {
              showToken:true,
              token: response.data.data || '',
              existingToken:response.data.data ||"",
              deviceHistory:[response.data.data,...this.state.deviceHistory],
              disableSubmit : true

          }
        )
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate('Share location link generated'),
            autoDismiss: 10
          })
        )
      } else {
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate('error'),
            autoDismiss: 10
          })
        )
      }
    })
    .catch(error => {errorHandler(error,this.props.dispatch)}) 
  }
  
  // copyTokenToClipboard = async ()  => {
  //   const { token } = this.state;

  //   const hostUrl = this.props.ServerSetting?.attributes?.hostUrl || '';
  //   const tokenUrl = `${hostUrl}/loginasuser?&${btoa(token)}`;

  //   await navigator.clipboard.writeText(tokenUrl)

    // this.props.dispatch(
    //   Notifications.success({
    //     message: this.props.translate('Token copied to clipboard'),
    //     autoDismiss: 10
    //   })
    // )
  // };


  copyTokenToClipboard = () => {
    const { token } = this.state;
    // let 
    // const hostUrl = this.props.ServerSetting?.attributes?.hostUrl || '';

    const tokenUrl = `http://localhost:3002/loginasuser?&${token}`;

    // Create a temporary textarea element to copy the token URL to clipboard
    if(tokenUrl){
    const tempTextarea = document.createElement('textarea');
    tempTextarea.value = tokenUrl;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextarea);

    this.props.dispatch(
      Notifications.success({
        message: this.props.translate('Token copied to clipboard'),
        autoDismiss: 10
      })
    )
    }
    // Show a notification indicating that the token URL has been copied to clipboard
    // this.props.enqueueSnackbar('Token URL copied to clipboard', {
    //   variant: 'success',
    //   autoHideDuration: 2000,
    // });
  };

copyTokenToClipboardNew = async () => {
    const { token } = this.state;

    const tokenUrl = `${this.props?.ServerSetting?.attributes?.hostUrl +'/locationshare?&' + token + '&shareLocation=true'}`;
  
    try {
      await navigator.clipboard.writeText(tokenUrl);
      this.props.dispatch(
        Notifications.success({
          message: this.props.translate('Location copied to clipboard'),
          autoDismiss: 10
        })
      );
    } catch (err) {
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate('Copy to clipboard failed'),
          autoDismiss: 10
        })
      );
    }
  }
  

  copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  handleCopyClick = () => {
    const { token,showToken } = this.state;
    const updatedToken=showToken?token:this.state.existingToken
    const tokenUrl = `${this.props?.ServerSetting?.attributes?.hostUrl +'/locationshare?&' + updatedToken + '&shareLocation=true'}`;
    this.copyTextToClipboard(tokenUrl).then(() => {
      this.props.dispatch(
        Notifications.success({
          message: 'Location copied to clipboard',
          autoDismiss: 5,
        })
      );
    }).catch(() => {
      this.props.dispatch(
        Notifications.error({
          message: 'Failed location copy to clipboard.',
          autoDismiss: 5,
        })
      );
    });
  }

  clearForm =()=>{
    this.setState(
      {
          showToken:false,
          token: '',
          selectedDate:'',
          disableSubmit : true,
          existingToken:""

      },
    )
  }
  handleRangeSelection = (range) => {
    const currentDate = moment();
    let to = '';
    switch (range) {
      case '1_hour':
        to = moment(currentDate).add(1, 'hour');
        break;
      case '3_hours':
        to = moment(currentDate).add(3, 'hours');
        break;
      case '1_day':
        to = moment(currentDate).add(1, 'day');
        break;
      case '3_days':
        to = moment(currentDate).add(3, 'days');
        break;
      default:
        break;
    }
    this.setState({
      showToken:false,
      
      isCustomSelected:range === 'custom' ? true : false,
      selectedDate:range,
      from: currentDate.toISOString(),
      to:range === 'custom' ? '' :  to.toISOString(),
    },()=>{
      this.checkReqFields()
        if (range !== 'custom') {
          this.submit();
        }
    });
  };

  handleCustomDateChange = (value) => {
    this.setState({ to: value });
  };

  componentDidMount(){
    if(this.props.device){
      this.fatchDevice(this.props.device.id)
    }
    this.setState({
      existingToken:this.props.device.locSharingToken
    })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.device.locSharingToken !== this.props.device.locSharingToken) {
      this.setState({
        existingToken: this.props.device.locSharingToken
      });
    }
  }


//   handleChange = (name, value) => {
//     let timezone = 'Asia/Riyadh';
//     if (
//         this.props.logInUser &&
//         this.props.logInUser.attributes &&
//         this.props.logInUser.attributes.timezone
//     ) {
//         timezone = this.props.logInUser.attributes.timezone;
//     }
//     moment.tz.setDefault(timezone);

//     if (name === "from") {
//         // Get the current moment time
//         let currentMomentTime = moment().format("HH:mm:ss.SSS");
//         // Combine the entered date with the current moment time
//         let val = moment(value).format("YYYY-MM-DD") + "T" + currentMomentTime + "Z";
//         this.setState({
//             [name]: val,
//         }, () => this.checkReqFields());
//     } else if (name === "to") {
//         // Get the entered time
//         let enteredTime = moment(value).format("HH:mm:ss.SSS");
//         // Combine the entered date with the entered time
//         let val = moment(value).format("YYYY-MM-DD") + "T" + enteredTime + "Z";
//         this.setState({
//             [name]: val,
//         }, () => this.checkReqFields());
//     }
// };
handleChange = (name, value) => {
  let timezone = serverTimeZone;
  if (this.props.logInUser?.attributes?.timezone) {
      timezone = this.props.logInUser.attributes.timezone;
  }

  moment.tz.setDefault(timezone);

  if (name === "from") {
      let val = moment(value).tz(timezone).format();
      this.setState({
          [name]: val,
      }, () => this.checkReqFields());
  } else if (name === "to") {
      let val = moment(value).tz(timezone).format();
      this.setState({
          [name]: val,
      }, () => this.checkReqFields());
  }
};
myFunction = (id) => {
  // Get the text field
  var copyText = document.getElementById(id);
  copyText.select();
  copyText.setSelectionRange(0, 99999); // For mobile devices
  // navigator.clipboard.writeText(copyText.value);
  document.execCommand('copy', true, copyText.value);
  if(copyText.value){
    this.props.dispatch(
      Notifications.success({
        message: 'Location copied to clipboard',
        autoDismiss: 5,
      })
    );
  }
}

fatchDevice = id => {
  source = CancelToken.source()
  this.setState({
    apiLoading:true
  })
    instance({
      method: 'GET',
      url: `/api/devices/share/history?deviceId=${id}`,
      cancelToken: source.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(response){

        this.setState({
          deviceHistory:response?.slice()?.reverse(),
          apiLoading:false
        })
      }
  })
  .catch(() => {
      this.setState({
        apiLoading:false
      })
      // errorHandler(error, this.props.dispatch)
    })
  }


  getTimeFormat=(passValue)=>{
    let timezone = 'Asia/Riyadh';
    let timeFormat = "YYYY-MM-DD HH:mm:ss";
    if (
        this.props.logInUser &&
        this.props.logInUser.attributes &&
        this.props.logInUser.attributes.timezone
    ) {
        timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);
    let value=moment(passValue).tz(timezone).format(timeFormat);
    return value
  }
  render () {
    let {deviceHistory,selectedDate,apiLoading}=this.state
    const endMessage =
     deviceHistory?.length > 0 ? (
      <p style={{ textAlign: 'center', color: '#ccc' }}>
        {' '}
        -- {this.props.translate('end')} --{' '}
      </p>
    ) : (
      <p style={{ textAlign: 'center', color: '#ccc' }}>
        {' '}
        {this.props.translate('noShareLinkGenerated')}{' '}
      </p>
    )
    return (
      <>
        <Grid container style={{ height: '100%', padding: 10 }}>

          <Grid item xs={12}   
              style={{
                  display: 'flex',
                }}>
            {/* <DateRangePicker
              fill
              futureDates
              error={ this.props.timeError || false}
              selectedDate={this.state.selectedDate}
              label={
                this.props.translate('reportFrom') +
                ' ' +
                this.props.translate('reportTo')
              }
              locationShare
              minDate={moment().startOf('day')}
              onEvent={this.showDates}
            /> */}
          {/* <Button onClick></Button> */}
          <Button 
            style={{ backgroundColor: this.state.selectedDate === '1_hour' ? 'green' : '', borderColor: this.state.selectedDate === '1_hour' ? 'yellow' : '' }}
            onClick={() => this.handleRangeSelection('1_hour')}>1 Hour</Button>

          <Button 
            style={{marginLeft:"10px", backgroundColor: this.state.selectedDate === '3_hours' ? 'green' : '', borderColor: this.state.selectedDate === '1_hour' ? 'yellow' : '' }}
            onClick={() => this.handleRangeSelection('3_hours')}>3 Hours</Button>

          <Button 
            style={{marginLeft:"10px", backgroundColor: this.state.selectedDate === '1_day' ? 'green' : '', borderColor: this.state.selectedDate === '1_hour' ? 'yellow' : '' }}
             onClick={() => this.handleRangeSelection('1_day')}>1 Day</Button>

          <Button 
            style={{marginLeft:"10px", backgroundColor: this.state.selectedDate === '3_days' ? 'green' : '', borderColor: this.state.selectedDate === '1_hour' ? 'yellow' : '' }}
            onClick={() => this.handleRangeSelection('3_days')} >3 Days</Button>

          <Button 
            style={{marginLeft:"10px", backgroundColor: this.state.selectedDate === 'custom' ? 'green' : '', borderColor: this.state.selectedDate === '1_hour' ? 'yellow' : '' }}
            onClick={() => this.handleRangeSelection('custom')}>Custom Range</Button>
          </Grid>

          
          {this.state.isCustomSelected &&  (
          <Grid container spacing={2} style={{marginTop:"10px"}}>
             <Grid item md={6} sm={6} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  format="dd/MM/yyyy HH:mm"
                  required
                  // error={false}
                  margin="dense"
                  disabled={true }
                  label={this.props.translate("from")}
                  variant="outlined"
                  fullWidth
                  views={["year", "month", "day"]}
                
                  value={
                    this.props && this.state && this.state.from
                      ? new Date(`${this.state.from}`)
                      : null
                  }
                  // allowEmpty={true}
                  onChange={(e) => this.handleChange("from", e)}
                  InputLabelProps={{
                    style: { color: "white" } // Set label font color to white
                  }}
                  InputProps={{
                    style: { color: "white" } // Set selected date text color to white
                  }}
                />
            </MuiPickersUtilsProvider>
          </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    format="dd/MM/yyyy HH:mm" // Specify the format to include both date and time
                    margin="dense"
                    required
                    label={this.props.translate("to")}
                    variant="outlined"
                    fullWidth
                    value={
                      this.props && this.state && this.state.to
                        ? moment(this.state.to).tz(serverTimeZone).toDate()
                        : null
                    }
                    onChange={(e) => this.handleChange("to", e)}
                    InputLabelProps={{
                      style: { color: "white" } // Set label font color to white
                    }}
                    InputProps={{
                      style: { color: "white" } // Set selected date text color to white
                    }}
                  />
                </MuiPickersUtilsProvider>
            </Grid> 
          </Grid>
          )}
             <Grid
              item
              xs={12}
              style={{
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                display: 'flex',
                marginTop:"10px",
                gap:"5px"
              }}
            >

          {
            selectedDate === 'custom'&&
            <Button
            disabled={this.state?.disableSubmit}
            onClick={this.submit}
            >
                  {this.props.translate('submit')}
                </Button>
            }
            </Grid>
          <Scrollbar
            disableTracksWidthCompensation={true}
            style={{
              height: 32 * 10
            }}
            scrollerProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    id='scrollbarShareLocation'
                  />
                )
              }
            }}
          >
            <>
            {
              apiLoading?(<Loader defaultStyle={true}/>):(
         
          
       
            <InfiniteScroll
            dataLength={deviceHistory?.length}
            loader={<Loader defaultStyle={true} />}
            scrollableTarget='scrollbarShareLocation'
            endMessage={endMessage}
          >
            {deviceHistory?.length > 0 &&
              deviceHistory?.map((device, index) => {
              const textareaId = `myInput${index}`;
          return (
          <React.Fragment key={index}>
          {/* <div
            style={{
              marginTop: "10px",
              display: 'flex',
              justifyItems: "center",
              gap: '8px',
              color: '#ededed',
              fontSize:'12px'
            }}
          >
            <div style={{ display: "flex", flexDirection: 'column', gap: '4px' }}>
              <p>Creation Time</p>
              <p>Expiration Time</p>
            </div>
            <div style={{ display: "flex", flexDirection: 'column', gap: '4px' }}>
              <p>{this.getTimeFormat(device?.created)}</p>
              <p>{this.getTimeFormat(device?.expirationTime)}</p>
            </div>
          </div>
          <textarea
            type="text"
            value={`${this.props?.ServerSetting?.attributes?.hostUrl}/locationshare?&${device?.token}`}
            id={textareaId}
            style={{
              border: "none",
              backgroundColor: "transparent",
              color: 'white',
              outline: "none",
              width: "95%",
              whiteSpace: "pre-wrap",
              wordBreak: "break-all",
              resize: 'none',
              height: '80px',
              fontSize: '14px',
              textDecoration: "underline",
              marginTop: '5px',
            }}
            readOnly
          />
          <Grid
            item
            xs={12}
            style={{
              justifyContent: 'flex-end',
              display: 'flex',
            }}
          >
            <Tooltip title={this.props.translate('copyLink')}>
              <Button
                style={{ marginRight: "15px" }}
                className='pull-right'
                onClick={() => this.myFunction(textareaId)}
              >
                <FileCopyOutlinedIcon />
              </Button>
            </Tooltip>
          </Grid> */}
          <Grid container style={{padding:"10px 0px",borderBottom:'2px solid #cdcdcd',marginTop:"15px"}}>
          <Grid item md={4} sm={4} xs={4}>
          <div style={{ display: "flex", flexDirection: 'column', gap: '4px',color:'#cdcdcd', fontSize:'14px' }}>
              <p style={{margin:"0px"}}>Creation Time</p>
              <p style={{margin:"0px"}}>{this.getTimeFormat(device?.created)}</p>
            </div>
          </Grid>
          <Grid item md={4} sm={4} xs={4}>
          <div style={{ display: "flex", flexDirection: 'column', gap:"1px",color:'#cdcdcd', fontSize:'14px' }}>
              <p style={{display:"flex",gap:"4px",margin:"0px"}}>
                <span style={{color:'red',marginTop:'-5px'}}><TimerOffIcon/></span>
               <span>Expiration Time</span>
              </p>
              <p style={{margin:"0px"}}>{this.getTimeFormat(device?.expirationTime)}</p>
            </div>
          </Grid>
          <Grid item md={4} sm={4} xs={4}>
          <Tooltip title={this.props.translate('copyLink')}>
              <Button
                style={{ marginRight: "15px" }}
                className='pull-right'
                onClick={() => this.myFunction(textareaId)}
              >
                <FileCopyOutlinedIcon />
              </Button>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={12}
style={{padding:'5px 0px'}}
          >
          <textarea
            type="text"
            value={`${this.props?.ServerSetting?.attributes?.hostUrl}/locationshare?&${device?.token}`}
            id={textareaId}
            style={{
              border: "none",
              backgroundColor: "transparent",
              color: 'white',
              outline: "none",
              width: "98%",
              whiteSpace: "pre-wrap",
              wordBreak: "break-all",
              resize: 'none',
              // height: '80px',
              fontSize: '14px',
              textDecoration: "underline",
              marginTop:"8px"
            }}
            readOnly
          />

          </Grid>
          </Grid>
       
        </React.Fragment>
      );
    })}
    </InfiniteScroll>)
           
          }
             </>
  </Scrollbar >

   
            
        </Grid>
      </>
    )
  }
}

export { ShareLocation }
