import React, { useEffect,  useState } from 'react'
import { connect } from 'react-redux'
// import isEqual from 'react-fast-compare'
import moment from 'moment'
import { withLocalize } from 'react-localize-redux'
import Layout from './../../Layout'
import Loader from './../../Layout/Loader'
import Tabs from './../../Components/common/Tabs'
import EmptyState from './../../Components/common/EmptyState'
import { 
  checkPrivileges, 
  errorHandler } from '../../Helpers'
import Notifications from 'react-notification-system-redux'
import { removeTemplate } from './../../Actions/Templates'
import instance from '../../axios'

const reportTypes = [
  { id: 'tripssummary', label: 'Trips', type: 'reportTrip' },
  { id: 'stopssummary', label: 'Stops', type: 'reportStop' },
  { id: 'eventssummary', label: 'Events', type: 'reportEvent' },
  { id: 'enginehourssummary', label: 'Engine Hours', type: 'reportEngineHour' },
  { id: 'geosummary', label: 'Geofence', type: 'reportGeo' },
  { id: 'fuelsummary', label: 'Fuel', type: 'reportFuel' }
]

const itemGroupTypes = {
  device: 'Device',
  driver: 'Driver',
  vehicle: 'Vehicle',
}

const checkReportTypes= () => {
  let array = reportTypes
  array.map((item, index) => {
    if (!checkPrivileges(item.type)) {
      array.splice(index)
    }
    return null
  })
}
let columns = []
let source;
const ReportData = {}

// class Reports extends Component {
const Reports = (props) => {

  const [state, setState] = useState({
      templatesCall: false,
      view: 'displayForm',
      from: '',
      to: '',
      selectedDate: '',
      reportType: 0,
      reportType1: 0,
      deviceId: [],
      vehicleId: [],
      groupId: [],
      driverId: [],
      geofenceId: [],
      sensorId: [],
      sensorValues: [{input: "", index: "", value: ""}],
      reportsData: ReportData,
      tripsDialog: false,
      loading: false,
      validatedForm: false,
      detail: false,
      detail1: false,
      groupView: 1,
      reportUnits: 'units',
      notificationIds: [],
      types: [],
      minimalNoDataDuration: 0,
      minimalParkingDuration: 0,
      minimalTripDuration: 0,
      minimalTripDistance: 0,
      speedThreshold: 0,
      processInvalidPositions: false,
      useIgnition: false,
      controlDays: [1, 2, 3, 4, 5, 6, 7],
      controlTimeStart: '00:00',
      controlTimeEnd: '23:59',
      controlTime: [0, 1439],
      geoTimeThreshold: 1,
      isSensorTracing: false,
      timeError: false,
      isSubmit: false,
      tripsColumns: {
        averageSpeed: { checked: true },
        distance: { checked: false },
        driverName: { checked: false },
        driverUniqueId: { checked: false },
        duration: { checked: true },
        maxSpeed: { checked: true },
        spentFuel: { checked: false },
        endAddress: { checked: true },
        endOdometer: { checked: false },
        endPositionId: { checked: false },
        endLat: { checked: false },
        endLon: { checked: false },
        startAddress: { checked: true },
        startOdometer: { checked: false },
        startPositionId: { checked: false },
        startLat: { checked: false },
        startLon: { checked: false },
        endTime: { checked: false },
        startTime: { checked: false }
      },
      reportDevices: [],
      advanceSettingsForm: {},
      allowFields: [],
      eventType: 'notification',
      steps: [{id: 1, title: 'selectReportTemplate'}, {id: 2, title: 'selectReportTimeControl'}],
      activeStep: 0,
      allSensors: false,
      enableRawDataAnalysis:false,
      // includePartiallyInRange:false
    })

    useEffect(() => {
      if (source) {
        source.cancel();
      }
  }, [source]);

    useEffect(() => {
    checkReportTypes();
  }, []);

  useEffect(()=>{
    if (state.reportUnits === '' && state.updateReportUnits !== null )
    setState(prevState=>({
      ...prevState,
      reportUnits: state.updateReportUnits,
      vehicleId: [],
      deviceId: [],
      groupId: [],
      driverId: []
    }))
  },[state.reportUnits,state.updateReportUnits])

    // const prevProps = useRef(props);
    // const prevState = useRef(state);
  
    // useEffect(() => {
    //   const propsChanged = !isEqual(prevProps.current, props);
    //   const stateChanged = !isEqual(prevState.current, state);
  
    //   if (propsChanged || stateChanged) {
    //     // Your logic for when props or state change
    //   }
  
    //   prevProps.current = props;
    //   prevState.current = state;
    // }, [props, state]);


  // shouldComponentUpdate (nextProps, nextState) {
  //   return !isEqual(nextProps, props) || !isEqual(nextState, state)
  // }

  // componentWillUnmount () {
  //   if(source)
  //     {
  //       source.cancel()
  //     }
  // }

  const switchGroupView = type => {
     setState((prevState) => ({ ...prevState,
      groupView: type,
      groupId:[],
      vehicleId: [],
      deviceId: [],
      driverId: []
    }));
  }

  const advanceFormChange  =(event, name)  =>{
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value

     setState((prevState) => ({ ...prevState,
      advanceSettingsForm: { ...state.advanceSettingsForm, [name]: value }
    }))
  }

  const handleChange = (name, event) => {
    let v = event.target.value;
    if(name === 'geoTimeThreshold') v = event.target.value < 0 ? 0 : event.target.value
     setState((prevState) => ({ ...prevState,
      [name]: v
    }))
  }

  const reportTypeSelection = (type) => {
    setState((prevState) => ({
      ...prevState,
      reportUnits: '',
    }));
    setState((prevState) => ({
      ...prevState,
      reportUnits: type,
      vehicleId: [],
      deviceId: [],
      groupId: [],
      driverId: [],
    }));
  }


  // const reportTypeSelection  =(type)  =>{
  //    setState((prevState) => ({ ...prevState, reportUnits: '' }, () => {
  //      setState((prevState) => ({ ...prevState,
  //       reportUnits: type,
  //       vehicleId: [],
  //       deviceId: [],
  //       groupId: [],
  //       driverId: []
  //     }))
  //   }))
  // }



  // const onCloseModal  =(e, name)  =>{
  //    setState((prevState) => ({ ...prevState, [name]: false }))
  // }

  const saveData = (blob, filename) =>{
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      const a = document.createElement('a')
      document.body.appendChild(a)
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = filename
      a.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
         setState((prevState) => ({ ...prevState, loading: false }))
      })
    }
     setState((prevState) => ({ ...prevState, loading: false }))
  }

  // const calcTime = (date, offset) => {
  //   let d = new Date(date)

  //   let utc = d.getTime() + d.getTimezoneOffset() * 60000

  //   let nd = new Date(utc + 3600000 * offset)

  //   return nd.toISOString()
  // }

  const createReport = (type) => {
    const duration = moment.duration(moment(state.to).diff(state.from));
    const hours = duration.asHours();

    setState((prevState) => ({ ...prevState, isSubmit: true }));

    if (!(state.isSensorTracing && hours >= 24)) {
      let header;
      let { reportType } = state;

      setState((prevState) => ({
        ...prevState,
        detail1: [1, 2, 3, 4, 5, 6, 13].includes(reportType),
      }));

      const form = {
        rpTmplId: state.reportType,
        to: state.to,
        from: state.from,
        controlDays: state.controlDays,
        controlTimeStart: moment().startOf('day').add(state.controlTime[0], 'minutes').format('HH:mm'),
        controlTimeEnd: moment().startOf('day').add(state.controlTime[1], 'minutes').format('HH:mm'),
        deviceIds: state.deviceId || [],
        vehicleIds: state.vehicleId || [],
        itemGroupIds: state.groupId || [],
        types: state.types,
        itemGroupType: state.groupView === 2 ? itemGroupTypes[state.reportUnits] : '',
        notificationIds: state.notificationIds,
        geofenceIds: state.geofenceId || [],
        sensors: state.sensorId || [],
        detail: false,
        driverIds: state.driverId || [],
        mail: false,
        geoTimeThreshold: (state.geoTimeThreshold || 0) * 60000,
        enableRawDataAnalysis:state.enableRawDataAnalysis||false,
        // includePartiallyInRange:state.includePartiallyInRange||false
      };

      let temp = '';
      
      props.templates.map(t => {
        if (form.rpTmplId === t.rpTmpl.id) {
          temp = t.rpTmpl.name;
        }
        return null;
      });

      let accept = 'application/pdf';
      if (type === 'downloadPdf' || type === 'downloadExcel') {
        accept = type === 'downloadPdf'
          ? 'application/pdf'
          : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        header = {
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: accept,
          }),
          method: 'POST',
          body: JSON.stringify(form),
        };

        setState((prevState) => ({ ...prevState, loading: true, reportsData: {} }));
        fetch('/api/reports/v2/dynamic', { ...header })
          .then((response1) => {
            if (response1.status === 200) {
              response1.blob().then((response) => {
                const fileName = `${temp || state.reportType}-reports.${type === 'downloadPdf' ? 'pdf' : 'xlsx'}`;
                saveData(response, fileName);
                setState((prevState) => ({ ...prevState, loading: false, detail: state.detail1 }));
              });
            } else {
              throw response1;
            }
          })
          .catch((error) => {
            if (error && error.text) {
              error.text().then((err) => {
                const message = err.split('-')[0];
                if (message) {
                  props.dispatch(
                    Notifications.error({
                      message: props.translate(message),
                      autoDismiss: 10,
                    })
                  );
                }
              });
            }
            setState((prevState) => ({ ...prevState, loading: false }));
          });
      } else if (type === 'email') {
        form.mail = true;
        header = {
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
          method: 'POST',
          body: JSON.stringify(form),
        };

        setState((prevState) => ({ ...prevState, loading: true, reportsData: {} }));
        fetch('/api/reports/v2/dynamic', { ...header })
          .then((response) => {
            setState((prevState) => ({ ...prevState, loading: false }));
            if (response.ok) {
              setState((prevState) => ({ ...prevState, detail: state.detail1 }));
              props.dispatch(
                Notifications.success({
                  message: props.translate('emailSentSuccessfully'),
                  autoDismiss: 10,
                })
              );
            } else {
              throw response;
            }
          })
          .catch((error) => {
            if (error && error.text) {
              error.text().then((err) => {
                const message = err.split('-')[0];
                if (message) {
                  props.dispatch(
                    Notifications.error({
                      message: props.translate(message),
                      autoDismiss: 10,
                    })
                  );
                }
              });
            }
            setState((prevState) => ({ ...prevState, loading: false }));
          });
      } else {
        setState((prevState) => ({ ...prevState, loading: true, reportsData: {}, reportType1: '', detail: false }));
        instance({
          url: `/api/reports/v2/dynamic`,
          method: 'POST',
          data: { ...form },
        })
          .then((reports) => {
            if (reports.success) {
              if (reports.report && reports.report.sheets) {
                const uniqueColumns = reports.report.sheets.flatMap((sheet) => 
                  sheet.sections?.flatMap((section) => section.columns || []) || []
                );
                const filterColumns = uniqueColumns.filter((value, index, self) => 
                  index === self.findIndex((col) => col.f === value.f)
                );
                setState((prevState) => ({
                  ...prevState,
                  reportsData: {
                    ...reports.report,
                    sheets: reports.report.sheets.map((sheet) => ({
                      ...sheet,
                      sections: sheet.sections?.map((section) => ({
                        ...section,
                        columns: filterColumns
                      })) || [],
                    })) || [],
                  },
                  reportType1: prevState.reportType,
                  detail: prevState.detail1,
                }));
              }
            }

            // if (reports.success) {
            //   setState((prevState) => ({
            //     ...prevState,
            //     reportsData: reports.report,
            //     reportType1: state.reportType,
            //     detail: state.detail1,
            //   }));
            // }
          })
          .catch((error) => {
            errorHandler(error, props.dispatch);
            setState((prevState) => ({ ...prevState, loading: false }));
          });
      }
    } else {
      validatedCurrentStep(1, '', -1);
      props.dispatch(
        Notifications.error({
          message: props.translate('sensorTracingLimitError'),
          autoDismiss: 10,
        })
      );
      setState((prevState) => ({ ...prevState, timeError: true }));
    }
  };

  const handleChangeCheckbox=(name)=>(event)=>{
    setState((prevState)=>({...prevState,[name]:event.target.checked}))
  }

  const updateState = (updates) => {
    setState((prevState) => ({ ...prevState, ...updates }));
  };

  const showDates = (e, date) => {
    const duration = moment.duration(moment(state.to).diff(state.from));
    const hours = duration.asHours();

    if (state.isSubmit && state.isSensorTracing && hours >= 24) {
      updateState({ timeError: true });
    } else {
      updateState({ timeError: false });
    }

    let timezone = 'Asia/Dubai';
    if (props.ServerSetting?.attributes?.timezone) {
      timezone = props.ServerSetting.attributes.timezone;
    }
    if (props.logInUser?.attributes?.timezone) {
      timezone = props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);

    const startDateUtc = moment(date.startDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const endDateUtc = moment(date.endDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    const timeFormat = props.logInUser?.twelveHourFormat ? 'hh:mm A' : 'HH:mm';

    updateState({
      selectedDate: `${moment(date.startDate).format('YYYY-MM-DD ' + timeFormat)} - ${moment(date.endDate).format('YYYY-MM-DD ' + timeFormat)}`,
      from: startDateUtc,
      to: endDateUtc
    });
  };

  useEffect(() => {
    if (
      (state.groupId.length || state.vehicleId.length || state.deviceId.length || state.driverId.length) &&
      state.reportType &&
      state.selectedDate
    ) {
      updateState({ validatedForm: true });
    } else {
      updateState({ validatedForm: false });
    }
  }, [state.groupId, state.vehicleId, state.deviceId, state.driverId, state.reportType, state.selectedDate]);

  // const onDismiss= ()=> {}

 const  selectedNotifications = types => {
     setState((prevState) => ({ ...prevState, types }))
     if (
      (state.groupId.length ||
        state.vehicleId.length ||
        state.deviceId.length ||
        state.driverId.length) &&
        state.reportType &&
        state.selectedDate
      ) {
        setState((prevState) => ({ ...prevState, validatedForm: true }))
      } else {
        setState((prevState) => ({ ...prevState, validatedForm: false }))
      }
  }

  const selectedEvents = notificationIds => {
     setState((prevState) => ({ ...prevState, notificationIds }))
     if (
      (state.groupId.length ||
        state.vehicleId.length ||
        state.deviceId.length ||
        state.driverId.length) &&
        state.reportType &&
        state.selectedDate
      ) {
        setState((prevState) => ({ ...prevState, validatedForm: true }))
      } else {
        setState((prevState) => ({ ...prevState, validatedForm: false }))
      }
  }

  const selectedDevices= (deviceId) =>{
    if (deviceId && deviceId.length) {
       setState((prevState) => ({ ...prevState, deviceId }))
       if (
         (state.groupId.length ||
          state.vehicleId.length ||
          state.deviceId.length ||
          state.driverId.length) &&
          state.selectedDate
        ) {
          setState((prevState) => ({ ...prevState, validatedForm: true }))
        } else {
          setState((prevState) => ({ ...prevState, validatedForm: false }))
        }
    } else {
       setState((prevState) => ({ ...prevState, deviceId: [] }))
    }
  }

  const selectedGroups= (groupId)=> {
    if (groupId && groupId.length) {
       setState((prevState) => ({ ...prevState, groupId }))
       if (
         (state.groupId.length ||
          state.vehicleId.length ||
          state.deviceId.length ||
          state.driverId.length) &&
          state.selectedDate
        ) {
          setState((prevState) => ({ ...prevState, validatedForm: true }))
        } else {
          setState((prevState) => ({ ...prevState, validatedForm: false }))
        }
    } else {
       setState((prevState) => ({ ...prevState, groupId: [] }))
    }
  }
  
 const  selectedVehicles = (vehicleId) => {
    if (vehicleId && vehicleId.length) {
       setState((prevState) => ({ ...prevState, vehicleId }))
       if (
         (state.groupId.length ||
          state.vehicleId.length ||
          state.deviceId.length ||
          state.driverId.length) &&
          state.selectedDate
        ) {
          setState((prevState) => ({ ...prevState, validatedForm: true }))
        } else {
          setState((prevState) => ({ ...prevState, validatedForm: false }))
        }
    } else {
       setState((prevState) => ({ ...prevState, vehicleId: [] }))
    }
  }

  const selectedDrivers = driverId => {
    if (driverId && driverId.length) {
       setState((prevState) => ({ ...prevState, driverId }))
       if (
         (state.groupId.length ||
          state.vehicleId.length ||
          state.deviceId.length ||
          state.driverId.length) &&
          state.selectedDate
        ) {
          setState((prevState) => ({ ...prevState, validatedForm: true }))
        } else {
          setState((prevState) => ({ ...prevState, validatedForm: false }))
        }
    } else {
       setState((prevState) => ({ ...prevState, driverId: [] }))
    }
  }

  const selectedGeofences = geofenceId => {
    if (geofenceId && geofenceId.length) {
       setState((prevState) => ({ ...prevState, geofenceId }))
       if (
         (state.groupId.length ||
          state.vehicleId.length ||
          state.deviceId.length ||
          state.driverId.length) &&
          state.selectedDate
        ) {
          setState((prevState) => ({ ...prevState, validatedForm: true }))
        } else {
          setState((prevState) => ({ ...prevState, validatedForm: false }))
        }
    } else {
       setState((prevState) => ({ ...prevState, geofenceId: [] }))
    }
  }
  
 const  selectedSensors = sensorId => {
    if (sensorId && sensorId.length) {
      if(sensorId.length === 1 && sensorId[0] && sensorId[0].value === 'allSensors') {
         setState((prevState) => ({ ...prevState,allSensors: true}))
      } else {
         setState((prevState) => ({ ...prevState,allSensors: false}))
      }
       setState((prevState) => ({ ...prevState, sensorId: sensorId.map(e => e.value), sensorValues: sensorId }))
       if (
         (state.groupId.length ||
          state.vehicleId.length ||
          state.deviceId.length ||
          state.driverId.length) &&
          state.selectedDate
        ) {
          setState((prevState) => ({ ...prevState, validatedForm: true }))
        } else {
          setState((prevState) => ({ ...prevState, validatedForm: false }))
        }
    } else {
       setState((prevState) => ({ ...prevState, sensorId: [] }))
    }
  }

  const reportTypeChange = (event) => {
    const tpl = props.templates.find(
      temp => temp.rpTmpl.id === event.target.value
    );
    let allowFields = [];
    if (
      tpl.rpTmpl &&
      tpl.rpTmpl.attributes &&
      tpl.rpTmpl.attributes.allowFields
    ) {
      allowFields = tpl.rpTmpl.attributes.allowFields;
    }

    setState(prevState => {
      const currentSteps = prevState.steps.filter(step => step.id < 3);

      if (allowFields.some(r => ["eventType", "notification"].includes(r))) {
        // add notification step
        currentSteps.push({ id: 3, title: "selectEventOrNotification" });
      } else if (allowFields.some(r => ["geofence"].includes(r))) {
        // add geofence step
        currentSteps.push({ id: 4, title: "selectGeofence" });
      } else if (allowFields.some(r => ["sensor", "digitalSensor"].includes(r))) {
        // add sensor step
        currentSteps.push({ id: 5, title: "selectSensor" });
      }

      // Update state
      return {
        ...prevState,
        reportType: event.target.value,
        reportUnits: allowFields[0],
        allowFields,
        eventType: allowFields.includes(prevState.eventType)
          ? prevState.eventType
          : allowFields.includes('notification')
          ? 'notification'
          : allowFields.includes('eventType')
          ? 'eventType'
          : '',
        steps: currentSteps,
        validatedForm: (prevState.groupId.length ||
          prevState.vehicleId.length ||
          prevState.deviceId.length ||
          prevState.driverId.length) &&
          prevState.reportType &&
          prevState.selectedDate
      };
    });

    tpl.rpTmplTblWrappers.forEach(i => {
      if (i.rpTmplTbl.rpId === 8) {
        setState(prevState => ({
          ...prevState,
          isSensorTracing: true
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          isSensorTracing: false
        }));
      }
    });
  };

 const reportDetail = (checked) => {
  setState(prevState => ({...prevState, detail1: checked }))
  }

  const handleChangeType = (newEventType) => {
    setState(prevState => ({...prevState,
         eventType:newEventType
       }
     ))
   }
 
   useEffect(()=>{
     selectedNotifications([])
     selectedEvents([])
   },[state.eventType])
  
  const childRendered =()=> {
    if (state.loading === true && state.reportsData.head) {
       setState((prevState) => ({ ...prevState, loading: false }))
    }
  }

  const deleteTemplate = id => {
    instance({
      url: `/api/report/templates/${id}`,
      method: 'DELETE',
    })
    .then(() => {
        props.dispatch(removeTemplate({ id }))
    })
    .catch(error => {errorHandler(error,props.dispatch)})
  }

 const  onDayChange = (checked, obj) => {
    let dayArry = []
    if (checked) {
      dayArry = (state.controlDays || []).concat(parseInt(obj.id))
    } else {
      dayArry = (state.controlDays || []).filter(
        day => day !== parseInt(obj.id)
      )
    }
     setState((prevState) => ({ ...prevState,
      controlDays: dayArry
    }))
  }

 const  handleTimeControl = (event, controlTime) => {
     setState((prevState) => ({ ...prevState, controlTime }))
  }

 const  goToNextStep = (step, type, index) => {
    validatedCurrentStep(step, type, index);
  }
 const  goToBackStep = () => {
    const currentStep = state.activeStep;
       setState((prevState) => ({ ...prevState,activeStep: currentStep-1}))
  }

 const  validatedCurrentStep = (step, type, index) => {
    const steps = [];
    let validated = false;
    let errorMsg = null;
    if(step === 1) {

         // check template and unit, group or staff selected
         if(state.reportType) {
          if(state.allowFields.some(r=> ["device", "driver", "group", "vehicle"].includes(r)) && !state.groupId.length && !state.deviceId.length && !state.driverId.length && !state.vehicleId.length) {
            validated = false
            errorMsg= <span className="text-warning">{props.translate("Please must select anything from the above list section")}</span>
          }
          else {
            errorMsg= null
          validated = true
          
          }        
          
        }
        else {
          validated = false
          errorMsg= <span className="text-warning">Please select a template from above</span>
         
        }
     
      
    }
    else if(step === 2) {
       // check datetime
       if(state.from && state.to) {
        // validated = true
        errorMsg= null
        if(state.steps.length === 2){
          createReport(type)
        }else{
          validated = true
        }
      }
      else {
        validated = false
        errorMsg= <span className="text-warning">Please select from to from above</span>
      }
   
    }
    else if(step === 3) {
      // optional step
      // allow fields containing events, types make sure select any of.
      errorMsg= null
      if(state.steps.length === 3){
        createReport(type)
      } else {
        validated = true
      }
    }
    else if(step === 4) {
      // allow fields containing geofence, types make sure select any of. 
      validated = false
      errorMsg= <span className="text-warning">Please select a geofence in above list</span>
      if(state.geofenceId.length){
        errorMsg= null
        createReport(type)
        // validated = true
      }
    }
    else if(step === 5) {
      // allow fields containing sensor, types make sure select any of. 
      validated = false
      errorMsg= <span className="text-warning">Please enter a sensor in above form</span>
      if(state.sensorId.length) {
        createReport(type)
        errorMsg = null;
        // validated = true
      }
    }

    if(validated === true) {
       setState((prevState) => ({ ...prevState,activeStep: index + 1}))
    }

    state.steps.map(s => {
      if(s.id === step) {
        steps.push({...s, errorMsg})
      }
      else steps.push(s)
      
      return null
    })

     setState((prevState) => ({ ...prevState,steps}) )

  }

  // render () {
    let serverTimeZoneName = 'Asia/Dubai'
    if (
      props.ServerSetting &&
      props.ServerSetting.attributes &&
      props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = props.ServerSetting.attributes.timezone
    }

    if (
      props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = props.logInUser.attributes.timezone
    }
    return (
      <div>
        <Layout
          {...props}
          classFromChildren='has-padding'
          {...state}
          switchGroupView={switchGroupView}
          handleChange={handleChange}
          goToNextStep={goToNextStep}
          goToBackStep={goToBackStep}
          deleteTemplate={deleteTemplate}
          handleChangeType={handleChangeType}
          reportTypeChange={reportTypeChange}
          createReport={createReport}
          advanceSettings={state.advanceSettingsForm}
          // advanceSettings={state.advanceSettingsForm.advanceSettings}
          advanceSettingsForm={state.advanceSettingsForm}
          advanceFormChange={advanceFormChange}
          reportTypeSelection={reportTypeSelection}
          showDates={showDates}
          selectedDevices={selectedDevices}
          eventType={state.eventType}
          selectedNotifications={selectedNotifications}
          selectedEvents={selectedEvents}
          selectedGroups={selectedGroups}
          selectedDrivers={selectedDrivers}
          selectedVehicles={selectedVehicles}
          selectedGeofences={selectedGeofences}
          selectedSensors={selectedSensors}
          handleTimeControl={handleTimeControl}
          reportTypes={reportTypes}
          reportDetail={reportDetail}
          validatedForm={state.validatedForm}
          reportUnits={state.reportUnits}
          onDayChange={onDayChange}
          handleChangeCheckbox={handleChangeCheckbox}
          enableRawDataAnalysis={state.enableRawDataAnalysis}
          includePartiallyInRange={state.includePartiallyInRange}
        >
          <div className='main-content-page'>
            {!state.loading && !state.reportsData?.head ? (
              <div
                style={{
                  background: props.themecolors.backgroundColor,
                  borderRadius: 6,
                  color: props.themecolors.textColor
                }}
              >
                <EmptyState text={props.translate('noReportSelected')} />
              </div>
            ) : null}
            <Tabs
              heading={state.reportsData?.head}
              onRender={childRendered}
              tabs={state.reportsData?.sheets}
              columns={columns}
              reportType={state.reportType1}
              detail={state.detail}
              themecolors={props.themecolors}
              serverTimeZone={serverTimeZoneName}
              timeFormat= {props.logInUser&&props.logInUser.twelveHourFormat}
              fetching={state.loading}
              translate={props.translate}
            />
            <div
              style={{
                background: props.themecolors.backgroundColor,
                color: props.themecolors.textColor
              }}
            >
              {state.loading ? <Loader /> : null} 
            </div>
          </div>
        </Layout>
      </div>
    )
  // }
}

const mapStateToProps = state => ({
  devices: state.devices.data,
  logInUser: state.logInUsers,
  groups: state.groups,
  templates: state.templates,
  themecolors: state.themeColors,
  ServerSetting: state.ServerSetting
})

export default connect(mapStateToProps)((withLocalize(Reports)))
