import React, { useState } from "react"
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useExpanded, useResizeColumns } from 'react-table'
import { Table as MuiTable, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import { Pagination } from '@mui/lab'
import { getDateTimeFormat, PrepareAddress, setAttributeFormat } from "../../Helpers"
// import Button from "./Button"
import TextField from "./TextField"
import { Link } from 'react-router-dom'
import LaunchIcon from '@mui/icons-material/Launch'
import { Translate } from "react-localize-redux";
import axios from "axios"
import moment from "moment"
import { ReactComponent as LoaderIcon } from './../../assets/tracks-loader.svg'
import CommonMenu from "../Menu/CommonMenu"
import CustomDialog from './Dialog'
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import HtmlParser from 'react-html-parser'
import { useSelector } from "react-redux"
// Define a custom filter filter function!
export const filterGreaterThan = (rows, id, filterValue) => {
  return rows.filter(row => {
    const rowValue = row.original.find(e => e.f === id[0]);
    return rowValue.r >= filterValue
  })
}

filterGreaterThan.autoRemove = val => typeof val !== 'number'

// Define a custom filter filter function!
export const filterGreaterThan2 = (rows, id, filterValue) => {
  return rows.filter(row => {
      const rowValue = row.original[id[0]] || ": 0";
      const v = parseFloat(rowValue.split(': ').pop());
      return v >= filterValue
  })
}

filterGreaterThan2.autoRemove = val => typeof val !== 'number'
// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
export const SliderColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id, Header },
    }) => {
    // Calculate the min and max
    // using the preFilteredRows
    const [anchorEl, setAnchorEl] = React.useState(null)

    const [min, max] = React.useMemo(() => {
        const rowValue = preFilteredRows.length ? preFilteredRows[0].original.find(e => e.f === id) : { r: 0 };
        let min = rowValue?.r
        let max = rowValue?.r
        preFilteredRows && preFilteredRows.forEach(row => {
            const rowValue1 = row.original.find(e => e.f === id);
            min = Math.min(rowValue1?.r, min)
            max = Math.max(rowValue1?.r, max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div style={{display: 'inline-flex', alignItems: "center"}}>
          <a style={{display: 'inline-flex', alignItems: "center"}} href={null} onClick={(e)=> setAnchorEl(e.currentTarget)}><ArrowDropDownIcon fill="currentColor" /></a>
          <CommonMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <div style={{padding: "0 16px"}}>
              <div>{Header}</div>
              <input
                style={{width: "150px"}}
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={e => {
                setFilter(parseInt(e.target.value, 10))
                }}
              />
              <a style={{color: 'inherit'}} href={null} onClick={() => setFilter(undefined)}><RotateLeftIcon fill="currentColor" /></a>
            </div>
          </CommonMenu>
      </div>
    )
}


export const SliderColumnFilter2 = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
  // Calculate the min and max
  // using the preFilteredRows

  const [anchorEl, setAnchorEl] = React.useState(null)

  const [min, max] = React.useMemo(() => {
      const rowValue = preFilteredRows.length ? preFilteredRows[0].original[id] : ": 0";
      const v = rowValue.split(": ").pop();

      let min = parseFloat(v)
      let max = parseFloat(v)
      preFilteredRows &&  preFilteredRows.forEach(row => {
          const rowValue1 = row.original[id];
          const v1 = rowValue1.split(': ').pop();
          min = Math.min(parseFloat(v1), min)
          max = Math.max(parseFloat(v1), max)
      })
      return [min, max]
  }, [id, preFilteredRows])

  return (
      <div style={{display: 'inline-flex', alignItems: "center"}}>
        <a  style={{display: 'inline-flex', alignItems: "center"}} href={null} onClick={(e)=> setAnchorEl(e.currentTarget)}>
          <Translate id="filterByCount" /> <ArrowDropDownIcon fill="currentColor" /></a>
        <CommonMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <div style={{padding: "0 16px"}}>
              <div>
                <Translate id="filterByCount" /></div>
                <input
                  style={{width: "150px"}}
                  type="range"
                  min={min}
                  max={max}
                  value={filterValue || min}
                  onChange={e => {
                  setFilter(parseInt(e.target.value, 10))
                  }}
                />
                <a style={{color: 'inherit'}} href={null} onClick={() => setFilter(undefined)}><RotateLeftIcon fill="currentColor" /></a>
            </div>
          </CommonMenu>
      </div>
  )
}
  
 
  // Define a default UI for filtering
const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    }) => {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    



    return (
        <div style={{display: 'inline-flex', marginRight: 15}}>
          <TextField
          value={value || ""}
          onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          label={<Translate id="sharedSearch" />}
          style={{
          fontSize: '1.1rem',
          border: '0',
          marginTop: 0,
          marginBottom: 0
          }}
        />
        </div>
    )
}


  
// Define a default UI for filtering
function DefaultColumnFilter() {
    return null
}

function DrawTableCell ({cell, simple, serverTimeZone, openDialog}) {

    if (cell && cell.column && cell.column.id) {
      let v = null, image = [];
        switch (cell.column.id) {
          
          case 'address':
              v = simple ? cell.render('Cell') : <PrepareAddress {...{original: cell.row.original, index: cell.row.index, columnName: cell.column.id, start: 'latitude', end: 'longitude', alternate: 'coordinate' }} />
          break
  
          case 'entranceAddress':
              v = simple ? cell.render('Cell') : <PrepareAddress {...{original: cell.row.original, index: cell.row.index, columnName: cell.column.id, start: 'entranceLat', end: 'entranceLon'}} />
          break
  
          case 'exitAddress':
              v = simple ? cell.render('Cell') : <PrepareAddress {...{original: cell.row.original, index: cell.row.index, columnName: cell.column.id, start: 'exitLat', end: 'exitLon'}} />
          break
  
          case 'startAddress': 
              v = simple ? cell.render('Cell') : <PrepareAddress {...{original: cell.row.original, index: cell.row.index, columnName: cell.column.id, start: 'startLat', end: 'startLon'}} />
          break
  
          case 'endAddress':
              v = simple ? cell.render('Cell') : <PrepareAddress {...{original: cell.row.original, index: cell.row.index, columnName: cell.column.id, start: 'endLat', end: 'endLon'}} />
          break

          case 'startTime':
          case 'endTime':
          case 'entranceTime':
          case 'exitTime':
          case 'eventTime':
          
            v = simple ? new moment(cell.value)
              .tz(serverTimeZone || 'Asia/Dubai')
              .format(getDateTimeFormat()) : cell.render('Cell');
            break

            case 'fixTime':
            case 'serverTime':
            v = moment(cell.value).format(getDateTimeFormat());
            break
                      // ? moment(r.r).format(getDateTimeFormat())
          
          case 'coordinate':
            const val = cell.value ? cell.value.split(',') : [];
            if (val[0] && val[1]) {
              v = (
                <Link target='_blank' to={'/maps/' + val[0] + '/' + val[1]}>
                  {cell.value}
                  <LaunchIcon
                    style={{ verticalAlign: 'middle', fontSize: '1.3em' }}
                  />
                </Link>
              )
            }
          break
          case 'status':
            v =
            (cell.value && cell.value === 'completed') ? 
              <Translate id="completed" />:
            (cell.value && cell.value === 'required') ? 
              <Translate id="required" />:
            (cell.value && cell.value === 'expired') ? 
              <Translate id="expired" />:''
          break
          case 'files':
            image = cell.value && cell.value.split(',')
            v =
            (cell.value && cell.value !== 'undefined') ? 
            // <a href={null} onClick={e => window.open(cell.value)} download>Download</a>
            <span onClick={()=>openDialog(image)}>Download</span>
              : ''
          break
          case 'speed':
            v = setAttributeFormat ('speed', cell.value)
            break
          case 'startLat':
          case 'startLon':
          case 'endLat':
          case 'endLon':
          case 'latitude':
          case 'longitude':
          case 'entranceLat':
          case 'entranceLon':
          case 'exitLat':
          case 'exitLon':
            v = setAttributeFormat ('latitude', cell.value)
            break
          default:
            v = cell.render('Cell');
            break
        }
  
      return (
        <TableCell size="small" {...cell.getCellProps()}>{v}</TableCell>
      )
    } else {
        return cell ? <TableCell size="small" {...cell.getCellProps()}>{cell.render('Cell')}</TableCell> : <TableCell size="small" />
    }
}

function DrawTableHeadCell ({column, headGlobalFilter, ...rest}) {
  if (column && column.id) {
      switch (column.id) {
        
        case 'head':
          return <TableCell {...column.getHeaderProps()} {...rest} >
            
            <div>{headGlobalFilter} {column.canFilter ? column.render('Filter') : null}</div>
            <div
              {...column.getResizerProps()}
              className={`resizer ${
                column.isResizing ? 'isResizing' : ''
              }`}
            />
            </TableCell>
        default:
          return <TableCell {...column.getHeaderProps()} {...rest}>
            <div style={{display: 'inline-flex', alignItems: 'center'}}>{column.render('Header')}
            {column.canFilter ? column.render('Filter') : null}
            <div
              {...column.getResizerProps()}
              className={`resizer ${
                column.isResizing ? 'isResizing' : ''
              }`}
            />
            </div>
          </TableCell>;
      }

  } else {
      return <TableCell {...column.getHeaderProps()} {...rest} />
  }
}



function DrawTFootCell ({column, total}) {
    const c = column.id
    let v = total && total[c] ? total[c].v : ''

    switch (c) {
      case 'distance':
        v = Math.round(v * 100) / 100
        break
      default:
        break
    }
    return (
        <TableCell {...column.getFooterProps()} size="small">
          {total && total[c] ? v : ''}
          </TableCell>
    )
}

// Our table component
export const ReactBaseTable = ({ serverTimeZone, hiddenColumns, columns, data, total, renderRowSubComponent, disableFooter }) => {

  const themecolors=useSelector((state)=>state.themeColors)
    const [imagePopup, setImagePopup] = useState(false);
    const [images, setImages] = useState([]);
    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,

      footerGroups,
      rows,
      page, // Instead of using 'rows', we'll use page,
      pageCount,
      gotoPage,
      setPageSize,
      state: { pageIndex, pageSize },

    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        initialState: {expanded: { "0" : true}, hiddenColumns: hiddenColumns || []},
        
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useExpanded, // Use the useExpanded plugin hook
      useResizeColumns,
      usePagination
      
    )

    const openDialog = (image) =>{
      setImages(image)
      setImagePopup(true)
    }
    const closeDialog = () =>{
      setImagePopup(false)
    }

    return (
      <div style={{overflow: "hidden"}} className="react-base-table-wrapper">
        <div style={{maxWidth: "100%", overflow: 'auto'}}>
            <MuiTable {...getTableProps()} className="react-base-table">
              <TableHead>
                  {headerGroups.map(headerGroup => (
                  <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                      {headerGroup.headers.map((column,i) => (
                      <DrawTableHeadCell key={i} headGlobalFilter={<GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        />} size="small" column={column} style={{width: column.width}} />
                      ))}
                  </TableRow>
                  ))}
                  
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                  {page.length ? page?.map((row, i) => {
                  prepareRow(row)
                  return (
                      <>
                      <TableRow {...row.getRowProps()} key={i}>
                      {row && row?.cells?.map(cell => {
                          return <DrawTableCell cell={cell} serverTimeZone={serverTimeZone} openDialog={openDialog} key={cell}/>
                      })}
                      </TableRow>
                      {row.isExpanded && renderRowSubComponent ? (
                          <TableRow>
                            <TableCell style={{padding: 0, paddingLeft: 35}} size="small" colSpan={visibleColumns.length + 1}>
                              {/*
                                  Inside it, call our renderRowSubComponent function. In reality,
                                  you could pass whatever you want as props to
                                  a component like this, including the entire
                                  table instance. But for this example, we'll just
                                  pass the row
                                */}
                              {renderRowSubComponent({ row })}
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </>
                  )
                  }) : <TableRow><TableCell colSpan={visibleColumns.length}><Translate id="noRecordExist" /></TableCell></TableRow> }
              </TableBody>
              {imagePopup?(<CustomDialog
                  title={<Translate id='Images'/>}
                  // themecolors={this.props.themecolors}
                  visable={true}
                  onClose={closeDialog}
                  bodyPadding={10}
                  cancelText={<Translate id='sharedCancel'/>}
                  noFullScreen
                >
                  { images.length ?(<div style={{ display: 'flex',flexWrap: 'wrap'}}>
                    <ImageList cols={2.5} style={{flexWrap: 'nowrap'}}>
                    {images.map((item, i) =>
                      item && (<ImageListItem key={item} style={{height: 'auto', width:'auto'}}>
                        <a href={item} download target="_blank" rel="noopener noreferrer">
                          <Tooltip 
                            classes={{
                            popper: 'menu-popper',
                            tooltip: 'menu-popper-tooltip'
                          }}
                          title={<Translate id='DOWNLOAD'/>}>
                            <FileCopyIcon style={{width: 80, height: 80, color: '#ffffff'}}/>
                          </Tooltip>
                        </a>
                        <p style={{textAlign: 'center', marginTop: 0}}>File {i+1}</p>
                        </ImageListItem>))}
                      </ImageList>
                  </div>) : <h3 style={{display: 'flex',justifyContent: 'center'}}>No Image Selected</h3>}
                </CustomDialog>):null}
              {!disableFooter ? (rows.length > 0 ? <TableFooter>
                {footerGroups?.map((group,i) => (
                  <TableRow {...group.getFooterGroupProps()} key={i}>
                    {group.headers?.map(column => <DrawTFootCell column={column} total={total} key={column}/>)}
                  </TableRow>
                ))}
              </TableFooter> : null) : null}
            </MuiTable>
        </div>
        {(pageIndex === 0 && rows?.length < pageSize) ? null :
        <div style={{display: "flex", alignItems: "center"}}>
          <Pagination count={pageCount} showFirstButton showLastButton onChange={(e, page) => gotoPage(page - 1)} />
          <TablePagination
            style={{marginLeft: 'auto'}}
            
            component="div"
            count={data && data.length || 0}
            page={pageIndex}
            onPageChange={(e, page) => gotoPage(page)}
            rowsPerPage={pageSize}
             onRowsPerPageChange={e => setPageSize(e.target.value)}
             SelectProps={{
              MenuProps: {
                sx: {
                  '& .MuiMenu-list': {
                    display: 'flex',
                    flexDirection: 'column',
                    background: themecolors.themeDarkColor ,
                    color: themecolors.textColor,
                    borderRadius:0,
                  },
                  '& .MuiMenuItem-root': { 
                     margin:'5px 0px',
                        '&:hover': {
                         backgroundColor: themecolors.themeLightColor,           
                                    },
                                          },
                },
              },
            }}
          />
        </div>}
      </div>
    )
}
  


// Let's add a fetchData method to our Table component that will be used to fetch
// new data when pagination state changes
// We can also add a loading state to let our table know it's loading new data
function Table({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  totalRecords,
  serverTimeZone
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    visibleColumns,
    rows,
    
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useResizeColumns,
    usePagination
  )

  React.useEffect(() => {
    fetchData({ pageIndex, pageSize })
  }, [fetchData, pageIndex, pageSize])

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value))
  }

  return (
    <div style={{ overflow: "hidden" }} className="react-base-table-wrapper">
      <div style={{ maxWidth: "100%", overflow: 'auto' }}>
        <MuiTable {...getTableProps()} className="react-base-table">
          <TableHead>
            {headerGroups?.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                {headerGroup.headers?.map(column => (
                  <TableCell size="small" {...column.getHeaderProps()} style={{ width: column.width }} key={column.id}>
                    {column.render('Header')}
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {loading ? (
              <TableRow><TableCell colSpan={visibleColumns.length}><LoaderIcon style={{ width: 40, height: 60 }} /></TableCell></TableRow>
            ) : (page.length ? page.map((row) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()} serverTimeZone={serverTimeZone} key={row.id}>
                  {row.cells?.map(cell => (
                    <TableCell {...cell.getCellProps()} key={cell.column.id} style={{padding:"6px"}}>
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              )
            }) : <TableRow><TableCell colSpan={visibleColumns.length}>Record not found</TableCell></TableRow>)}
          </TableBody>
        </MuiTable>
      </div>
      {(pageIndex === 0 && rows?.length < pageSize) ? null :
        <div style={{ display: "flex", alignItems: "center" }}>
          <Pagination page={pageIndex + 1} count={pageCount} showFirstButton showLastButton onChange={(e, page) => gotoPage(page - 1)} />
          <TablePagination
            style={{ marginLeft: 'auto' }}
            component="div"
            count={totalRecords}
            page={pageIndex}
            onPageChange={(e, page) => gotoPage(page)}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[10, 20, 40, 50, 75, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>}
    </div>
  )
}


export const RemoteReactTable = ({ columns, query }) => {

  // We'll start our table without any data
  const [data, setData] = React.useState([])
  const [totalRecords, setTotal] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [pageCount, setPageCount] = React.useState(0)
  // const fetchIdRef = React.useRef(0)


  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.

    // Give this fetch an ID
    // const fetchId = ++fetchIdRef.current

    // Set the loading state
    setLoading(true)

    axios.get(`${query}&limit=${pageSize}&page=${pageIndex+1}`).then(response => {
      if(response.status === 200 && response.data && response.data.status === "success") {
        const { data, total } = response.data.data;
        data && data.map(r =>{
          r.address = HtmlParser(r.address)
          let attributes=[];
          Object.entries(r.attributes).map(([key,value])=>{
           let dataAttributes = setAttributeFormat (key, value)
           attributes.push([key,dataAttributes])

          })
          r.attributes = Object.fromEntries(attributes)
        })
        setData(data && data)
        setPageCount(Math.ceil(total / pageSize))
        setTotal(total);
        setLoading(false)
      }
    })
  }, [])

  return (
      <Table
        columns={columns}
        data={data && data || []}
        totalRecords={totalRecords}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
      />
  )
}